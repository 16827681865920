import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import Config from "../../../../Config_.js";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "../CustomButtons/Button.jsx";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Menu, Email, FileCopy, Archive, Delete, CastForEducation, Help, GetApp, Settings, 
    Notifications, Extension } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MaterialIcon from "@mdi/react";
import { mdiStar, mdiLinkBoxVariant, mdiShare, mdiAccountCog, mdiShieldLinkVariantOutline } from '@mdi/js';
import NewUpdateModal from "./NewUpdateModal.jsx";
import FirstTimeLoginModal from "./FirstTimeLoginModal.jsx";
import NewUpdate from "../../../assets/img/new-update.png";
import sidebarStyle from "../../../assets/jss/sidebarStyle.jsx";

class Sidebar extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        
        this.state = {
            isActive: false,
            newUpdateModal: false,
            firstTimeLoginModal: false,
            newUser: null,
            newUpdateData: this.getNewUpdateData(),
            firstTimeloginData: this.getFirstTimeLoginData()
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(authorized){
            if(user.login === 0){
                this.onFirstTimeLoginModal(true);
                this.updateLoginStatus();
            }
        }
    }
    getFilesMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/user/home?dir=/Email/Web"}>
            <Button color="transparent" className={classes.navLink}>Web</Button>
        </RouterLink>);
        // menuItems.push(<RouterLink to={"/user/home?dir=/Computer/App"} className={classes.dropdownLink}>Desktop</RouterLink>);
        menuItems.push(<RouterLink to={"/user/home?dir=/Email/Outlook"}>
            <Button color="transparent" className={classes.navLink}>Outlook</Button>
        </RouterLink>);      
        return menuItems;
    }
    getTrashMenu(){
        const { classes } = this.props;
        let menuItems = [];
        menuItems.push(<RouterLink to={"/user/trash/files"}>
            <Button color="transparent" className={classes.navLink}>Files</Button>
        </RouterLink>);
        menuItems.push(<RouterLink to={"/user/trash/links"}>
            <Button color="transparent" className={classes.navLink}>Links</Button>
        </RouterLink>);      
        return menuItems;
    }
    toggleMenu(){
        this.setState({
            isActive: !this.state.isActive
        })
    }
    getNewUpdateData(){
        const { user, authorized } = this.store.getState();
        
        let newUpdateData = null;
        if(authorized && user.loginVideos.length > 0){
            user.loginVideos.map(video => {
                if(video.type === 0){
                    newUpdateData = video;
                }

                return null;
            });
        }

        return newUpdateData;
    }
    onNewUpdateModal(status = false){
        this.setState({
            newUpdateModal: status
        })

        if(status === true){
            this.updateNewUpdateStatus()
        }else{
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.state.newUser));
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: this.state.newUser}});
        }
    }
    updateNewUpdateStatus(){
        const values = {}
        const source = axios.CancelToken.source();
        Api.updateNewUpdateStatus(values, source).then((data) => {
            let newUser = Api.prepareMemberObject(data.user);
            this.setState({
                newUser: newUser
            })
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    updateLoginStatus(){
        const values = {}
        const source = axios.CancelToken.source();
        Api.updateLoginStatus(values, source).then((data) => {
            let newUser = Api.prepareMemberObject(data.user);
            this.setState({
                newUser: newUser
            })
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    getMyDocsMenu(){
        const { classes } = this.props;
        const { authorized, user } = this.store.getState();
        let menuItems = [];
        if(authorized && user.extensions && user.extensions.hasOwnProperty("one_drive") && user.extensions.one_drive.status){
            menuItems.push(<RouterLink to={"/user/home?dir=/OneDrive"}>
            <Button color="transparent" className={classes.navLink}>One Drive</Button>
           </RouterLink>);
        }
        if(authorized && user.extensions && user.extensions.hasOwnProperty("sharepoint") && user.extensions.sharepoint.status){
            menuItems.push(<RouterLink to={"/user/home?dir=/Sharepoint"}>
            <Button color="transparent" className={classes.navLink}>Sharepoint</Button>
           </RouterLink>);
        }
        // menuItems.push(<RouterLink to={"/user/home?dir=/Computer/App"} className={classes.dropdownLink}>Desktop</RouterLink>);      
        return menuItems;
    }
    onFirstTimeLoginModal(modal = true){
        this.setState({
            firstTimeLoginModal: modal,
        });

        if(this.state.newUser !== null){
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(this.state.newUser));
            this.store.dispatch({type: 'UPDATE_STATE',state: {user: this.state.newUser}});
        }
    }
    getFirstTimeLoginData(){
        const { user, authorized } = this.store.getState();

        let firstTimeloginData = null;
        if(authorized){
            if(user.loginVideos.length > 0){
                user.loginVideos.map(video => {
                    if(user.level_id === 5){
                        if(video.type === 5){
                            firstTimeloginData = video;
                        }
                    }else{
                        if((user.account_type === 1 || user.account_type === 2) && video.type === 2){
                            firstTimeloginData = video;
                        }else if(user.account_type === 3 && video.type === 3){
                            firstTimeloginData = video;
                        }else if(user.account_type === 4 && video.type === 4){
                            firstTimeloginData = video;
                        }else if(user.account_type === 6 && video.type === 6){
                            firstTimeloginData = video;
                        }
                    }
                    return null;
                });
            }
        }

        return firstTimeloginData;
    }
    render(){
        let isDesktop = isWidthUp('md', this.props.width);
        const { classes } = this.props;
        const { isActive, newUpdateData, newUpdateModal, firstTimeLoginModal, firstTimeloginData } = this.state;
        const { user, authorized } = this.store.getState();

        return (
            <>
                <div className={classes.container}>
                    {
                        isDesktop === false ?
                            <div className={classes.toggleMenu}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.toggleMenu}
                                >
                                    <Menu />
                                </IconButton>
                            </div>
                        :
                        <></>
                    }
                    <div className={(isDesktop ? "" : (classes.collapse+" "+(isActive ? "active": "")))}>
                        <List className={classes.list + " " + classes.mlAuto}>
                            {
                                user.level_id !== 5 ?
                                    <>
                                        {
                                            Config.isLimitedAccess() === false ?
                                                <>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/send">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 3760 3500">
                                                                    <path d="M970 3489c-52-16-86-45-108-92-19-39-178-874-169-883 8-8 888 629 884 640-3 12-423 297-472 320-43 21-95 26-135 15zm1322-152c-40-15-1104-787-1100-798 2-5 379-378 838-830C3215 542 3575 185 3559 195c-8 5-614 481-1348 1059-734 577-1339 1049-1345 1049-13 0-743-529-790-572-67-61-89-186-47-268 45-90-23-61 1743-738C2675 379 3472 74 3542 48c138-53 167-54 195-11 9 13 16 36 15 51-1 19-1046 2900-1130 3113-18 45-74 101-127 125-56 27-146 31-203 11z"/>
                                                                </svg>
                                                                Send
                                                            </Button>
                                                        </RouterLink>                        
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/home?dir=/Email">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <Email />Email Transfers
                                                            </Button>
                                                        </RouterLink>
                                                        <List className={classes.list + " " + classes.mlAuto}>
                                                            {
                                                                this.getFilesMenu().map((item, key) => {
                                                                    return (
                                                                        <ListItem key={key} className={classes.listItem}>
                                                                            {item}
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </ListItem>
                                                </>
                                            :
                                            null
                                        }
                                        <ListItem className={classes.listItem}>
                                            <RouterLink to="/user/home?dir=/My Docs">
                                                <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                    <FileCopy />My Docs
                                                </Button>  
                                            </RouterLink>                 
                                        </ListItem>
                                        {
                                            Config.isLimitedAccess() === false ?
                                                <>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/archives-transfers">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <Archive />Archives
                                                            </Button>  
                                                        </RouterLink>                      
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <Button color="transparent" className={classes.navLink+" "+classes.textMenu+" "+classes.navLinkCustom}>
                                                            <Delete />Trash
                                                        </Button>                        
                                                        <List className={classes.list + " " + classes.mlAuto}>
                                                            {
                                                                this.getTrashMenu().map((item, key) => {
                                                                    return (
                                                                        <ListItem key={key} className={classes.listItem}>
                                                                            {item}
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/favourites">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <MaterialIcon path={mdiStar} />
                                                                Quick Access
                                                            </Button>                        
                                                        </RouterLink>
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/links">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <MaterialIcon path={mdiLinkBoxVariant} />
                                                                Link Manager
                                                            </Button>
                                                        </RouterLink>
                                                    </ListItem>
                                                    <ListItem className={classes.listItem}>
                                                        <RouterLink to="/user/external-links">
                                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                                <MaterialIcon path={mdiShieldLinkVariantOutline} />
                                                                External Links
                                                            </Button>
                                                        </RouterLink>
                                                    </ListItem>
                                                </>
                                            :
                                            null
                                        }
                                    </>
                                :
                                <></>
                            }
                            {
                                Config.isLimitedAccess() === false ?
                                    <ListItem className={classes.listItem}>
                                        <RouterLink to="/user/shared">
                                            <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                <MaterialIcon path={mdiShare} />
                                                Shared
                                            </Button> 
                                        </RouterLink>                       
                                    </ListItem>
                                :
                                null
                            }
                            {
                                user.level_id !== 5 && Config.isLimitedAccess() === false && user.settings.tutorialsMenuUrl !== '' ?
                                    <>
                                        <ListItem className={classes.listItem}>
                                            <Link href={user.settings.tutorialsMenuUrl} target="_blank">
                                                <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                    <CastForEducation />Tutorials
                                                </Button>    
                                            </Link>                    
                                        </ListItem>
                                        <ListItem className={classes.listItem}>
                                            <RouterLink to="/user/support">
                                                <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                    <Help />Support
                                                </Button>  
                                            </RouterLink>                      
                                        </ListItem>
                                        {
                                            user.account_type >= 3 ?
                                                <ListItem className={classes.listItem}>
                                                    <RouterLink to="/user/downloads">
                                                        <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                            <GetApp />Downloads
                                                        </Button>    
                                                    </RouterLink>                    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                        <ListItem className={classes.listItem}>
                                            <RouterLink to="/user/settings">
                                                <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                    <Settings />Settings
                                                </Button>   
                                            </RouterLink>             
                                        </ListItem>
                                    </>
                                :
                                <></>
                            }
                            {
                                authorized ?
                                    <>
                                        {
                                            user.user_type === "business" ?
                                                <ListItem className={classes.listItem}>
                                                    <RouterLink to="/users">
                                                        <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                            <MaterialIcon path={mdiAccountCog} />
                                                            Admin
                                                        </Button>    
                                                    </RouterLink>                    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                        {
                                            user.level_id !== 5 && Config.isLimitedAccess() === false && user.settings.recentUpdatesMenuUrl !== '' ?
                                                <ListItem className={classes.listItem}>
                                                    <Link href={user.settings.recentUpdatesMenuUrl} target="_blank">
                                                        <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                            <Notifications />Recent Updates
                                                        </Button>    
                                                    </Link>                    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                        {
                                            firstTimeloginData !== null ?
                                                <ListItem className={classes.listItem}>
                                                    <Button color="transparent" onClick={() => this.onFirstTimeLoginModal(true)} className={classes.navLink+" "+classes.navLinkCustom+" "+classes.navButtonLink}>
                                                        <svg width="167" height="152" viewBox="0 0 167 152">
                                                            <g transform="matrix(1 0 0 1 90 80)">
                                                                <g>
                                                                    <g transform="matrix(1 0 0 1 -27.3784 -17.2838)">
                                                                        <g vectorEffect="non-scaling-stroke">
                                                                            <g transform="matrix(1 0 0 1 0 0)">
                                                                                <path transform=" translate(-64, -64)" d="M 127 1 H 1 v 126 h 126 V 1 z M 119 119 H 9 V 9 h 110 V 119 z" strokeLinecap="round"/>
                                                                            </g>
                                                                            <g transform="matrix(1 0 0 1 7 0.05)">
                                                                                <path transform=" translate(-71, -64.05)" d="M 98.7 64 L 43.3 32.1 l 0 63.9 L 98.7 64 z M 82.7 64 L 51.3 82.1 l 0 -36.1 L 82.7 64 z" strokeLinecap="round"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g transform="matrix(4.9891 0 0 4.9891 31.5088 21.4142)">
                                                                        <g vectorEffect="non-scaling-stroke">
                                                                            <g transform="matrix(1 0 0 1 0.0002 5.35)">
                                                                                <path transform=" translate(-12.0002, -1045.75)" d="m 8.4062 1041.1 c -2.8856 1.3 -4.9781 4 -5.3437 7.3 c 0 1.1 0.8329 2 1.9375 2 h 14 c 1.105 0 1.938 -0.9 1.938 -2 c -0.366 -3.3 -2.459 -6 -5.344 -7.3 c -0.649 1.3 -2.011 2.3 -3.594 2.3 s -2.9453 -1 -3.5938 -2.3 z" strokeLinecap="round"/>
                                                                            </g>
                                                                            <g transform="matrix(1 0 0 1 0 -5)">
                                                                                <path transform=" translate(-12, -4)" d="m 17 4 a 5 5 0 1 1 -10 0 a 5 5 0 1 1 10 0 z" strokeLinecap="round"/>
                                                                            </g>
                                                                            <g transform="matrix(1 0 0 1 0.0002 4)">
                                                                                <path transform=" translate(-12.0002, -16)" d="m 12 11 c -1.277 0 -2.4943 0.269 -3.5938 0.75 c -2.8856 1.262 -4.9781 3.997 -5.3437 7.25 c 0 1.105 0.8329 2 1.9375 2 h 14 c 1.105 0 1.938 -0.895 1.938 -2 c -0.366 -3.253 -2.459 -5.988 -5.344 -7.25 c -1.1 -0.481 -2.317 -0.75 -3.594 -0.75 z" strokeLinecap="round"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        Welcome
                                                    </Button>    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                        {
                                            newUpdateData !== null && user.new_update === 0 && user.level_id !== 5 && Config.isLimitedAccess() === false ?
                                                <ListItem className={classes.listItem}>
                                                    <Button color="transparent" onClick={() => this.onNewUpdateModal(true)} className={classes.navLink+" "+classes.navLinkCustom+" just-icon "+classes.navButtonLink}>
                                                        <img className='just-icon' src={NewUpdate} alt="new-update" />
                                                    </Button>    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                        {
                                            user.level_id !== 5 && Config.isLimitedAccess() === true ?
                                                <ListItem className={classes.listItem}>
                                                    <RouterLink to={"/user/extensions"}>
                                                        <Button color="transparent" className={classes.navLink+" "+classes.navLinkCustom}>
                                                            <Extension />Extensions
                                                        </Button>    
                                                    </RouterLink>                    
                                                </ListItem>
                                            :
                                                <></>
                                        }
                                    </>
                                :
                                    <></>
                            }
                        </List>
                    </div>
                </div>
                {
                    newUpdateModal ?
                        <NewUpdateModal
                            open={newUpdateModal}
                            onClose={() => this.onNewUpdateModal(false)}
                            newUpdateData={newUpdateData}
                        />
                    :
                        <></>
                }
                {
                    firstTimeLoginModal ?
                        <FirstTimeLoginModal
                            store={this.store}
                            open={firstTimeLoginModal}
                            onClose={() => this.onFirstTimeLoginModal(false)}
                            firstTimeloginData={firstTimeloginData}
                        />
                    :
                        <></>
                }
            </>
        )
    }
}

Sidebar.defaultProps = {
    align: "center",
    color: "primary"
}
Sidebar.propTypes = {
    classes: PropTypes.object
};

export default withWidth()(withStyles(sidebarStyle)(Sidebar));