import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from "react-router-dom";
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
// import Config from "../../../../Config";
import Cookie from "../../../assets/js/utils/Cookie";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/Sidebar";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridItem from "../../components/Grid/GridItem";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import DeleteFileModal from "../../components/User/DeleteFileModal";
import UploadFileModal from "../../components/User/UploadFileModal";
import ProcessingModal from "../../components/User/ProcessingModal";
import NewFolderModal from "../../components/User/NewFolderModal";
import CopyFilesModal from "../../components/User/SharedCopyFilesModal";
import MoveFilesModal from "../../components/User/SharedMoveFilesModal";
import RenameFileModal from "../../components/User/RenameFileModal";
import ShareModal from "../../components/User/ShareModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import NewFileModal from "../../components/User/NewFileModal";
import Menu from '@material-ui/core/Menu';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import newFolderIcon from "../../../assets/img/new_folder.png";
import newFolderIconLight from "../../../assets/img/new_folder-light.png";
import newOfficeDoc from "../../../assets/img/new_office_doc.png";
import newOfficeDocLight from "../../../assets/img/new_office_doc-light.png";
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiArrowCollapseDown, mdiTrashCanOutline, mdiStar, mdiStarOutline, mdiLinkVariant, mdiEyeOutline,
    mdiMessage } from '@mdi/js';
import newlinkicon from "../../../assets/img/New_Link.png";
import newlinkiconLight from "../../../assets/img/New_Link-light.png";
import relatedLinksIcon from "../../../assets/img/related_links.png";
import relatedLinksIconLight from "../../../assets/img/related_links-light.png";
import foldericon from "../../../assets/img/foldericon.png";
import sharedFolderIcon from "../../../assets/img/sharedFolderIcon.png";
import sharedFolderIconGreen from "../../../assets/img/sharedFolderIconGreen.png";
import { Add as ChevronRight, Close as CloseIcon, Refresh as RefreshIcon, Check, Edit } from "@material-ui/icons";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DragNDrop from "../../components/Drag/Drop";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import SearchInput from "../../components/User/SearchInput";
import OfficeFileModal from "../../components/User/OfficeFileModal";
import officeIcon from "../../../assets/img/office.svg";
import withStyles from "@material-ui/core/styles/withStyles";
import Config from '../../../../Config';
import ImagePreviewModal from "../../components/User/imageModal";
import PdfFileModal from "../../components/User/PdfFileModal";
import NoPreviewModal from "../../components/User/NoPreviewModal";
import ActivitiesModal from "../../components/User/ActivitiesModal";
import NoteModal from "../../components/User/NoteModal";
import FileNoteModal from "../../components/User/FileNoteModal";

import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const history = createBrowserHistory();

const UserShared = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);
        let fileId = null;
        let transferId = null;
        const { match } = this.props.match;
        if(match && match.params.hasOwnProperty("id")){
            fileId = match.params.id;
        }
        if(match && match.params.hasOwnProperty("transferId")){
            transferId = match.params.transferId;
        }
        this.editDocRef = React.createRef();
        this.editDocTokenRef = React.createRef();
        this.editDocSecretRef = React.createRef();

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: false,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            downloadFile: false,
            file: null,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null,
            fileId: fileId,
            rootPrivacy: null,
            transferId: transferId,
            currentFile: null,
            filter: "all",
            searchFormData: this.defaultSearchFormData(),
            ownerNotification: '',
            groupNotification: '',
            ownerId: null,
            sharedWith: [],
            showError: false,
            newLinkUploaded: false,
            officeFileModal: false,
            officeFileAction: "view",
            docMenuEl: null,
            newFileModal: false,
            newFileExtension: null,
            creatingFile: false,
            newFileError: false,
            newFileErrorMessage: null,
            newUser: null,
            saving: false,
            imagePreviewModal: false,
            pdfFileModal: false,
            noPreviewModal: false,
            activitiesModal: false,
            activities: [],
            activitiesFileId: null,
            noteModal: false,
            noteSavingModal: false,
            fileNoteModal: false
        };

        this.loadingFolder = false;
        this.onShareModal = this.onShareModal.bind(this);
        this.onShareModalSuccess = this.onShareModalSuccess.bind(this);
        this.onAdvShareModal = this.onAdvShareModal.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadFiles();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultSearchFormData(){
        return {
            fileName: '',
            searchIn: 'shared',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
        }
    }
    closeSnackbar(){
        this.setState({showError: false});
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadFiles()
        });
    }
    onSearchInputSuccess(searchData){
        this.setState({
            searchFormData: {
                fileName: searchData.fileName,
                searchIn: 'shared',
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadFiles();
        });
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadFiles(viewMore = false, newPath = null, backPath = "", file = null, filter = null){
        const source = axios.CancelToken.source();
        let { page, path, favourites, fileId, transferId, searchFormData } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        if(filter === null){
            filter = this.state.filter;
        }
        let requestData = {
            page: page,
            limit: 200,
            shareViewType: filter
        };
        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }
        backPath = this.getBackPath(path);
        let url = "/user/shared";
        if(file !== null){
            fileId = file.id;
        }
        if(path === "/" || path === ""){
            fileId = null;
        }
        if(fileId !== null){
            requestData['file_id'] = fileId;
            url += "/"+fileId;
        }
        if(transferId !== null){
            requestData['transferId'] = transferId;
        }

        // search for request data
        if(searchFormData.fileName){
            requestData['name'] = searchFormData.fileName;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }

        history.push(url+"?dir="+encodeURIComponent(path));
        Api.getSharedFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.favourite){
                    favourites.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                favourites: favourites,
                rootPrivacy: data.privacy,
                ownerNotification: ( data.ownerNotification ? data.ownerNotification : ''),
                groupNotification: ( data.groupNotification ? data.groupNotification : ''),
                ownerId: ( data.owner_id ? data.owner_id : null),
                sharedWith: ( data.sharedWith ? data.sharedWith : [])
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
            if(err.message !== "Request Cancelled"){
                this.setState({
                    loading: false,
                    showError: true,
                    errorMessage: err.message,
                    rootPrivacy: "",
                    files: [],
                    response: null
                });
            }
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null,
            fileId: fileId,
            transferId: null,
            filter: filter,
            checkedAll: false,
            noteModal: false,
            noteSavingModal: false,
            fileNoteModal: false
        });
        this.loadingFolder = true;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"form-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"form-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    handleToggle(file, onContextMenu = false) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }

        let newChecked = [];
        if(onContextMenu){
            newChecked = [file.id];
        }else{
            const { checked } = this.state;
            const currentIndex = checked.indexOf(file.id);
            newChecked = [...checked];
        
            if (currentIndex === -1) {
                newChecked.push(file.id);
            } else {
                newChecked.splice(currentIndex, 1);
            }
        }
    
        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null)
        });
    }
    openFileModal(file){
        if(this.loadingFolder){
            return;
        }
            
        let newChecked = [file.id];
        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null)
        },() => {
            if(helper.hasThumbnail(file)){
                this.onImagePreviewModal(true);
            }else if(helper.isOfficeDocument(file)){
                this.onOfficeFileModal('view');
            }else if(helper.isPdfDocument(file)){
                this.onPdfFileModal(true);
            }else{
                this.onNoPreviewModal(true)
            }
        });
    }
    onActivityModal(status = false, activitiesFileId = null, activities = []){
        this.setState({
            activitiesModal: status,
            activities: activities,
            activitiesFileId: activitiesFileId
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        const { user } = this.store.getState();

        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/shared" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                notes: "",
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
                lastModifiedBy: "",
                activity: "",
                recipients: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            if(file.type === "folder" && file.path === "/Web/My Docs"){
                return null;
            }
            let fileArray = {
                id: file.id,
                default: file.default,
                orignalName: file.name,
                name: (
                    <ContextMenuTrigger id="file_dropdown_menu" collect={() => {return file}}>
                        {this.getFileName(file)}
                    </ContextMenuTrigger>
                ),
                notes: (
                    file.note ?
                        <div onClick={() => this.onFileNoteModal(true)}>
                            <MaterialIcon path={mdiMessage} className="MuiSvgIcon-root" />
                        </div>
                    :
                        <></>
                ),
                type: file.type,
                downloadLink: file.downloadLink,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.modified_date,
                owner: file.owner,
                lastModifiedBy: file.last_modified_by,
                activity: (
                    <>
                        <span>{ this.getActivityText(file.activity) }</span>
                        {
                            file.activity.length <= 0 ?
                                <></>
                            :
                                <Button color="transparent" simple onClick={() => this.onActivityModal(true, file.id, file.activities)}>
                                    More...
                                </Button>
                        }
                    </>
                ),
            };
            if(user.id === file.owner_id){
                fileArray.folderPath = file.folder_path;
            }
            if(user.level_id !== 5){
                fileArray.recipients = <div className={classes.recipients}>{this.getRecipients(file)}</div>;
                fileArray.relatedLinks = this.getRelatedLink(file);
            }
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getActivityText(activity){
        const { user } = this.store.getState();
        
        let activityText = "";
        if(activity.length <= 0){
            return activityText;
        }

        if(user.id === activity.owner_id){
            activityText += "You ";
        }else{
            activityText += activity.owner_name+" ";
        }

        let actionText = "";
        let action = activity.action.toLowerCase();
        switch(action){
            case 'upload':
                actionText = 'uploaded';
                break;
            case 'rename':
                actionText = 'renamed';
                break;
            case 'copy':
                actionText = 'copied';
                break;
            case 'delete':
                actionText = 'deleted';
                break;
            case 'move':
                actionText = 'moved';
                break;
            case 'downloaded document(s)':
                actionText = 'downloaded';
                break;
            case 'updated document(s)':
                actionText = 'updated';
                break;
            default:
                actionText = action;
                break;
        }

        activityText += actionText+' '+activity.file_name;

        return activityText;
    }
    getFileName(file){
        const { classes } = this.props;
        const { favourites, path } = this.state;
        const { user } = this.store.getState();
        let sharedFolderImage = sharedFolderIcon;
        if(path === '/' && file.owner_id === user.id){
            sharedFolderImage = sharedFolderIconGreen;
        }
        return (
            <div title={file.name} onDoubleClick={(e) => this.openFileModal(file)}>
                <Button justIcon color="transparent" className={classes.fileIcon} onClick={() => this.favourite(file)}>
                    {
                        user.level_id !== 5 ?
                            favourites.includes(file.id) ?
                                <MaterialIcon path={mdiStar} className="MuiSvgIcon-root star_filled" />
                            :
                                <MaterialIcon path={mdiStarOutline} className="MuiSvgIcon-root star_outlined" />
                        :
                           <></>
                    }
                    {
                        file.privacy === "write" || file.owner_id === user.id ?
                            <Edit className='privacy-icon' />
                        : file.privacy === 'read' ?
                            <MaterialIcon path={mdiEyeOutline} className="privacy-icon" />
                        :
                            <></>
                    }
                </Button>
                {
                    file.type === "folder" ?
                        <Link href="/user/shared" onClick={(e) => this.loadFolder(e, file)}>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                <img src={sharedFolderImage} alt={"folder"} />
                            </Button>
                            {file.name}
                        </Link>
                    :
                    <>
                        { 
                            helper.getFileIcon(file, classes, true)
                        }
                        {file.name}
                    </>
                }
            </div>
        )
    }
    getRecipients(file){
        if(file.transfer_type === "share"){
            return <div>Anyone (links only)</div>;
        }
        if(file.recipients.length <= 2){
            return <div>{file.recipients.join(",")}</div>;
        }
        const firstTwoRecipients = file.recipients.slice(0, 2);
        let recipientsList = [];
        file.recipients.map((email, key) => {
            recipientsList.push(
                <li key={key}>{email}</li>
            );
            return null;
        });
        return (
            <>
                <div>
                    {firstTwoRecipients.join(",")}
                </div>
                <ul>
                    {recipientsList}
                </ul>
            </>
        );
    }
    getRelatedLink(file){
        if(file.relatedLinks.length <= 0){
            return null;
        }
        const url = "/user/links?page=1&links="+file.relatedLinks.join(",");
        return (
            <RouterLink to={url}>
                <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
            </RouterLink>
        );
    }
    loadFolder(e, parent){
        e.preventDefault();
        const { fileId } = this.state;
        if(fileId === null){
            this.loadFiles(false, parent.name, "", parent);
        }else{
            this.loadFiles(false, parent.name);
        }
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false
        });
    }
    onModalSuccess(){
        const { checked, response, fileId } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }

        Api.deleteFiles(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                deleteAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    onUploadModal(uploadFileModal = true){
        if(!uploadFileModal){
            this.loadFiles(false);
            return;
        }
        this.setState({
            uploadFileModal: uploadFileModal,
        });
    }
    onUploadModalSuccess(){
        this.loadFiles(false);
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
    
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        }, () => {
            this.handleToggle(file);
        });
    }
    onShareModal(status = false){
        const { checked, path, currentFile } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
            path: path
        };
        if(typeof(path) === "string" && path.indexOf("/Web/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }

        if(currentFile !== null){
            requestData['sharedFileId'] = currentFile.id;
        }
        
        Api.shareFiles(requestData, source).then(data => {
            if(data.status === 'uploaded'){
                this.setState({
                    sharingFiles: false,
                    newLinkUploaded: true
                })

                return;
            }

            this.setState({sharingFiles: false, downloadUrl: data.downloadUrl});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message, sharingFiles: false});
            }
        });
        this.setState({
            shareFileModal: status,
            sharingFiles: status,
            cancelToken: source
        });
    }
    onAdvShareModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        this.setState({
            advShareModal: status,
        });
        if(!status){
            this.loadFiles();
        }
    }
    onShareModalSuccess(){
        this.setState({
            shareFileModal: false,
            errorMessage: null,
            newLinkUploaded: false
        });

        // const { downloadUrl } = this.state;
        // if(downloadUrl.length > 0){
        //     window.location.href = downloadUrl;
        // }
        this.history.push("/user/links");
    }
    onDownloadModal(status = false){
        const { checked, path, downloadFile, file, fileId, rootPrivacy } = this.state;
        if(checked.length <= 0){
            return;
        }

        if(downloadFile && checked.length === 1 && file){
            window.location.href = file.downloadLink;
            this.trackFileDownload();
            return;
        }

        let source = null;
        if(status){
            source = axios.CancelToken.source();
            const requestData = {
                files: checked,
                path: path
            };
            if(fileId !== null){
                requestData['sharedFileId'] = fileId;
            }else if(checked.length > 0){
                requestData['sharedFileId'] = checked[0];
            }
            Api.downloadFiles(requestData, source).then(data => {
                let state = {
                    downloadingFiles: false, 
                    downloadUrl: data.downloadUrl
                }

                if(rootPrivacy !== null && rootPrivacy !== "" && rootPrivacy === 'read'){
                    state['downloadModal'] = false;
                    state['errorMessage'] = null;
                }

                this.setState(state, () => {
                    if(rootPrivacy !== null && rootPrivacy !== "" && rootPrivacy === 'read' && this.state.downloadUrl.length > 0){
                        window.location.href = this.state.downloadUrl;
                        this.trackFileDownload();
                    }
                });
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({cancelToken: null, errorMessage: err.message, downloadingFiles: false});
                }
            });
        }

        this.setState({
            downloadModal: status,
            downloadingFiles: status,
            cancelToken: source
        });
    }
    trackFileDownload(){
        const { fileId, checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: checked,
            file_id: fileId,
        };
        Api.trackFileDownload(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    onDownloadModalSuccess(){
        this.setState({
            downloadModal: false,
            errorMessage: null
        });
        const { downloadUrl } = this.state;
        if(downloadUrl.length > 0){
            window.location.href = downloadUrl;
            this.trackFileDownload();
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path, rootPrivacy } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            let pathLabel = path;
            if(rootPrivacy !== null && rootPrivacy !== ""){
                if(rootPrivacy === 'write'){
                    pathLabel += " (Uploads Allowed)";
                }else if(rootPrivacy === 'read'){
                    pathLabel += " (View Only)";
                }
            }
            breadcrumbs.push({
                value: newPath,
                label: pathLabel
            });
            return null;
        });
        return breadcrumbs;
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status,
        });
    }
    onNewFolderModalSuccess(folderName){
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });
        const { fileId } = this.state;
        const requestData = {
            folderName: folderName,
            path: this.state.path
        };
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.addNewFolder(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            console.log(err);
        });
    }
    getColumns(){
        const { user } = this.store.getState();
        const modificationAllowed = this.isModificationAllowed();
        let columns = [
            {
                Header: "",
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Notes",
                accessor: "notes",
                headerClassName: "hd_notes hd_notes",
                className: "hd_notes td_notes",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
        ];
        if(user.level_id !== 5){
            const { path } = this.state;
            if(path !== "/" && path !== "/Computer" && path !== "/Computer/Outlook" && path !== "/Computer/App" && path !== "/Web/My Docs"){
                columns.push({
                    Header: "Recipient(s)",
                    accessor: "recipients",
                    headerClassName: "td_recipients",
                    className: "hd_recipients td_recipients",
                });
            }
        }
        columns.push({
            Header: "Creator",
            accessor: "owner",
            headerClassName: "hd_owner",
            className: "hd_owner td_owner",
        });
        columns.push({
            Header: "Last Modified By",
            accessor: "lastModifiedBy",
            headerClassName: "hd_owner",
            className: "hd_owner td_owner",
        });
        if(!modificationAllowed){
            columns.push({
                Header: "Activity",
                accessor: "activity",
                headerClassName: "hd_activity",
                className: "hd_activity td_activity",
            });
        }
        columns.push({
            Header: "Uploaded",
            accessor: "date",
            headerClassName: "hd_creation_date",
            className: "hd_creation_date td_creation_date",
            sortMethod: (a, b) => {
                var a1 = new Date(a).getTime();
                var b1 = new Date(b).getTime();
              if(a1<b1)
                return 1;
              else if(a1>b1)
                return -1;
              else
                return 0;
            }
        });
        columns.push({
            Header: "Folder Path",
            accessor: "folderPath",
            headerClassName: "hd_path",
            className: "hd_path td_path",
        });
        if(user.level_id !== 5){
            columns.push({
                Header: "Related Links",
                accessor: "relatedLinks",
                headerClassName: "hd_related_links",
                className: "hd_related_links td_creation_date",
            });
        }
        return columns;
    }
    onContextMenuItemClick(type, data){
        const checkedFile = this.getCheckedFile();
        switch(type){
            case 'share':
                this.onShareModal(true);
                break;
            case 'delete':
                this.deleteSelected();
                break;
            case 'download':
                this.onDownloadModal(true);
                break;
            case 'copy':
                this.onCopyModal(true);
                break;
            case 'move':
                this.onMoveModal(true);
                break;
            case 'rename':
                this.onRenameModal(true);
                break;
            case 'permission':
                this.onAdvShareModal(true);
                break;
            case 'view':
                this.onOfficeFileModal('view');
                break;
            case 'edit':
                if(checkedFile.extension === "wopitest" || checkedFile.extension === "wopitestx"){
                    this.onOfficeFileModal('edit');
                }else{
                    this.editOfficeDoc(checkedFile);
                }
                break;
            case 'imagePreview':
                this.onImagePreviewModal(true);
                break;
            case 'pdfPreview':
                this.onPdfFileModal(true);
                break;
            case 'note':
                this.onNoteModal(true);
                break;
            default:
                break;
        }
    }
    onPdfFileModal(status = false){
        let state = {};
        state['pdfFileModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onNoPreviewModal(status = false){
        let state = {};
        state['noPreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onImagePreviewModal(status = false){
        let state = {};
        state['imagePreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    editOfficeDoc(file, newFile = false){
        let expiresAt = Cookie.read("expires_at");
        if(!expiresAt || expiresAt === "null"){
            expiresAt = 0;
        }
        // const url = Config.getWopiApiUrl()+"document/edit/"+checkedFile.id;
        let url = window.location.origin+"/document/edit/"+file.id;
        if(newFile){
            url = window.location.origin+"/document/editnew/"+file.id;
        }
        // this.editDocTokenRef.current.value = Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret");
        this.editDocRef.current.setAttribute("action", url);
        this.editDocRef.current.submit();
        // this.editDocTokenRef.current.value = '';
    }
    onOfficeFileModal(action, status = true){
        this.setState({
            officeFileModal: status,
            officeFileAction: action
        });
    }
    onContextMenuShow(e){
        const { checked, path } = this.state;
        if(checked.length > 1){
            return;
        }
        const file = e.detail.data;
        let state = {};
        state['currentFile'] = (file ? file : null);
        if(path === "/"){
            state['sharedWith'] = (file ? file.sharedWith : null);
            state['ownerId'] = (file ? file.owner_id : null);
        }
        this.setState(state, () => {
            this.handleToggle(file, true);
        });
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    onCopyModal(modal = true){
        this.setState({
            copyFilesModal: modal,
            errorMessage: null
        });
    }
    onCopyModalSuccess(newPath){
        const source = axios.CancelToken.source();
        this.setState({
            copyingFilesModal: true,
            cancelToken: source,
        });
        const { checked, fileId } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.copyFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    copyingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onMoveModal(modal = true){
        this.setState({
            moveFilesModal: modal,
            errorMessage: null
        });
    }
    onMoveModalSuccess(newPath){
        const source = axios.CancelToken.source();
        this.setState({
            movingFilesModal: true,
            cancelToken: source,
        });
        const { checked, fileId } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.moveFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    movingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onRenameModal(modal = true){
        this.setState({
            renameFileModal: modal,
            errorMessage: null
        });
    }
    onRenameModalSuccess(newFileName){
        const checkedFile = this.getCheckedFile();
        if(checkedFile === null){
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            renamingFileModal: true,
            cancelToken: source,
        });
        const requestData = {
            name: newFileName,
        };
        const { fileId } = this.state;
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.renameFile(checkedFile.id, requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err && err.duplicate === true){
                this.setState({cancelToken: null, errorMessage: err.message, renamingFileModal: false});
            }
            console.log(err);
        });
    }
    getCheckedFile(checked = null){
        const { response } = this.state;
        if(checked === null){
            checked = this.state.checked;
        }
        if(checked.length <= 0){
            return null;
        }
        let currentFile = null;
        response.map((file) => {
            if(currentFile !== null){
                return null;
            }
            if(checked.indexOf(file.id) !== -1){
                currentFile = file;
            }
            return null;
        });
        return currentFile;
    }
    onDrop(filesArray){
        this.setState({
            files: filesArray,
            uploadFileModal: true
        });
    }
    isModificationAllowed(){
        const { rootPrivacy, ownerId } = this.state;
        const { user } = this.store.getState();

        if(rootPrivacy === "write" || ownerId === user.id){
            return true;
        }
        return false;
    }
    onRelatedLinks(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        const url = "/user/links?page=1&files="+checked.join(",");
        this.history.push(url);
    }
    onNewDocMenuSuccess(extension){
        this.setState({
            docMenuEl: null,
            newFileModal: true,
            newFileExtension: extension
        });
    }
    onNewFileModal(status = false){
        this.setState({
            docMenuEl: null,
            newFileModal: status,
            newFileExtension: null
        });
    }
    onNewFileModalSuccess(fileName){
        const { newFileExtension, fileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFile: true,
            cancelToken: source,
        });

        const requestData = {
            fileName: (fileName+"."+newFileExtension),
            path: this.state.path
        };
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.addNewFile(requestData, source).then(data => {
            this.editOfficeDoc(data, true);
            this.setState({
                docMenuEl: null,
                newFileModal: false,
                newFileExtension: null,
                creatingFile: false,
                newFileError: false,
                newFileErrorMessage: null
            }, () => {
                this.loadFiles();
            });
        }).catch(err => {
            console.log(err);
        });
    }
    updateLoginStatus(){
        const values = {}
        const source = axios.CancelToken.source();
        Api.updateLoginStatus(values, source).then((data) => {
            let newUser = Api.prepareMemberObject(data.user);
            localStorage.setItem("show_first_view_shared", false);
            this.setState({
                newUser: newUser
            })
        }).catch(err => {
            this.setState({
                savingInfo: false, 
                showError: true, 
                errorMessage: err.message
            });
        });
    }
    renderNewDocMenu(){
        const { docMenuEl } = this.state;
        const { classes } = this.props;
        return (
            <Menu
                id="new-doc-menu"
                anchorEl={docMenuEl}
                keepMounted
                open={Boolean(docMenuEl)}
                onClose={() => this.onNewFileModal(false)}
            >
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("docx")}>
                    {helper.getFileIcon({extension: "docx"}, classes)}
                    Word Document
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("xlsx")}>
                    {helper.getFileIcon({extension: "xlsx"}, classes)}
                    Excel Workbook
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("pptx")}>
                    {helper.getFileIcon({extension: "pptx"}, classes)}
                    Powerpoint Presentation
                </MuiMenuItem>
            </Menu>
        )
    }
    onNewOfficeDoc(event){
        this.setState({
            docMenuEl: event.currentTarget
        });
    }
    getFilesContent(files){
        const { classes } = this.props;
        const { loading, loadingMore, page, totalPages, fileId, checked, filter, searchFormData } = this.state; 
        const breadcrumbs = this.getBreadCrumbs();
        const tableData = this.getTableData();
        const modificationAllowed = this.isModificationAllowed();
        const { user } = this.store.getState();

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem>
                        <GridContainer className={classes.alignItems}>
                            <GridItem xs={12} sm={6} md={8} className={classes.flexItems}>
                                <h4>Shared</h4>
                                <div className={classes.filterGridItem+" "+classes.filterGridItemSm}>
                                    <ul className={classes.sharedFilters}>
                                        <li className={filter === "all" ? "active" : ""} onClick={() => this.loadFiles(false, null, "", null, "all")}>
                                            <span>All</span>
                                        </li>
                                        <li className={filter === "you" ? "active" : ""} onClick={() => this.loadFiles(false, null, "", null, "you")}>
                                            <span>With You</span>
                                        </li>
                                        <li className={filter === "others" ? "active" : ""} onClick={() => this.loadFiles(false, null, "", null, "others")}>
                                            <span>With Others</span>
                                        </li>
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>                                
                                <SearchInput searchFormData={searchFormData} onSuccess={(searchData) => this.onSearchInputSuccess(searchData)} clearSearch={() => this.clearSearch()} history={this.history} store={this.store} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                        {
                            modificationAllowed ?
                            <>
                                <Button color="transparent" onClick={() => this.onUploadModal(true)}>
                                    <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                    Upload
                                </Button>
                                <span className={classes.separator}></span>
                                <Button color="transparent" onClick={() => this.onNewFolderModal(true)}>
                                    <img src={user.theme === 'standard' ? newFolderIcon : newFolderIconLight} alt={"New Folder"} />
                                    New Folder
                                </Button>
                                <span className={classes.separator}></span>
                                {
                                    user.extensions.ms_office ?   
                                        <>
                                            <Button color="transparent" aria-controls="new-doc-menu" aria-haspopup="true" onClick={(event) => this.onNewOfficeDoc(event)}>
                                                <img src={user.theme === 'standard' ? newOfficeDoc : newOfficeDocLight} alt={"New Office Doc"} />
                                                New Office Doc
                                            </Button>
                                            <span className={classes.separator}></span>
                                            {this.renderNewDocMenu()}
                                        </>
                                    :
                                        <></>
                                }
                            </>
                            :
                            <></>
                        }
                        {
                            checked.length > 0 ?
                                <>
                                    {
                                        (fileId !== null || checked.length === 1) && modificationAllowed ?
                                        <>
                                            {
                                                user.level_id !== 5 ?
                                                    <>
                                                        {
                                                            modificationAllowed ?
                                                                <span className={classes.separator}></span>
                                                            :
                                                            <></>
                                                        }
                                                        <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onShareModal(true)}>
                                                            <img src={user.theme === 'standard' ? newlinkicon : newlinkiconLight} alt={"link"} />
                                                            New Link
                                                        </Button>
                                                    </>
                                                :
                                                <></>
                                            }
                                            {
                                                user.level_id !== 5 ?
                                                    <span className={classes.separator}></span>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                    {
                                        user.level_id !== 5?
                                            <>
                                                <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onRelatedLinks()}>
                                                    <img src={user.theme === "standard" ? relatedLinksIcon : relatedLinksIconLight} alt={"link"} /> Related Links
                                                </Button>
                                            </>
                                        :
                                        <></>
                                    }
                                    {
                                        modificationAllowed ?
                                            <>
                                                <span className={classes.separator}></span>
                                                <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.deleteSelected()}>
                                                    <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                                    Delete
                                                </Button>
                                            </>
                                        :
                                        <></>
                                    }
                                    {
                                        (fileId !== null || checked.length === 1) ?
                                            <>
                                                <span className={classes.separator}></span>
                                                <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onDownloadModal(true)}>
                                                    <MaterialIcon path={mdiArrowCollapseDown} className="MuiSvgIcon-root" />
                                                    Download
                                                </Button>
                                            </>
                                        :
                                        <></>
                                    }
                                </>
                            :
                                <></>
                        }
                    </div>
                </div>
                <div className={classes.breadcrumbs}>
                    <img src={foldericon} alt={"folder"} />
                    {
                        breadcrumbs.map((breadCrumb, key) => {
                            return (
                                <div key={key}>
                                    {
                                        key !== 0 ?
                                            <span><ChevronRight /></span>
                                        :
                                        <></>
                                    }
                                    <Link href="/user/shared" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, breadCrumb.value);}}>
                                        {breadCrumb.label}
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={classes.filesContainer}>
                    <div className={classes.files}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={tableData}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+classes.filesTable}
                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                        }
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
        
        if(modificationAllowed){
            return (
                <DragNDrop onDrop={(files) => this.onDrop(files)}>
                    { content }
                </DragNDrop>
            );
        }else{
            return (
                content 
            );
        }
    }
    groupNotificationStatus(){
        const { 
            // ownerId, 
            groupNotification 
        } =  this.state;
        // const { user } = this.store.getState();

        let status = groupNotification;
        // if(ownerId === user.id){
        //     status = "optional";
        // }

        return status;
    }
    canEditDocument(){
        const modificationAllowed = this.isModificationAllowed();
        if(!modificationAllowed){
            return false;
        }
        const checkedFile = this.getCheckedFile();
        return helper.canEditDocument(checkedFile);
    }
    onNoteModal(modal = true){
        let checked = this.state.checked;
        if(modal === false){
            checked = [];
        }
        this.setState({
            noteModal: modal,
            fileNoteModal: false,
            errorMessage: null,
            checked: checked,
            checkedAll: false,
        });
    }
    onNoteModalSuccess(note){
        const checkedFile = this.getCheckedFile();
        if(checkedFile === null){
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            noteSavingModal: true,
            cancelToken: source,
        });
        const requestData = {
            note: note,
        };
        const { fileId } = this.state;
        if(fileId !== null){
            requestData['sharedFileId'] = fileId;
        }
        Api.noteFile(checkedFile.id, requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err && err.duplicate === true){
                this.setState({cancelToken: null, errorMessage: err.message, noteSavingModal: false});
            }
            console.log(err);
        });
    }
    onFileNoteModal(modal = true){
        let checked = this.state.checked;
        if(modal === false){
            checked = [];
        }
        this.setState({
            fileNoteModal: modal,
            errorMessage: null,
            checked: checked,
            checkedAll: false,
            noteModal: false,
        });
    }
    render() {
        const { classes } = this.props;
        const { deleteFileModal, uploadFileModal, path, shareFileModal,
            downloadModal, downloadingFiles, newFolderModal, creatingFolder, copyFilesModal, copyingFilesModal,
            moveFilesModal, movingFilesModal, renameFileModal, renamingFileModal, files, errorMessage, checked, fileId,
            advShareModal, sharingFiles, ownerNotification, sharedWith,showError, newLinkUploaded, officeFileModal, officeFileAction,
            newFileModal, newFileExtension, creatingFile, newFileError, newFileErrorMessage, 
            ownerId, saving, imagePreviewModal, noPreviewModal, pdfFileModal, activitiesModal, activities, activitiesFileId,
            noteSavingModal, noteModal, fileNoteModal, currentFile } = this.state;
        
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        
        const canEdit = this.canEditDocument();
        const groupNotification = this.groupNotificationStatus()
        const checkedFiles = this.getCheckedFiles();
        const checkedFile = this.getCheckedFile();
        const modificationAllowed = this.isModificationAllowed();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    {
                        this.getFilesContent(files)
                    }
                    <DeleteFileModal
                        deletedFiles={checkedFiles}
                        open={deleteFileModal}
                        onClose={() => this.onModalClose(false)}
                        onSuccess={() => this.onModalSuccess()}
                        loading={saving}
                        errorMessage={errorMessage}
                    />
                    {
                        uploadFileModal ?
                            <UploadFileModal 
                                sharedFileId={fileId} 
                                ownerNotification={ownerNotification} 
                                groupNotification={(groupNotification)} 
                                open={uploadFileModal} 
                                store={this.store} 
                                path={path} 
                                files={files} 
                                onClose={() => this.onUploadModal(false)} 
                                onSuccess={() => this.onUploadModalSuccess()}
                                sharedWith={sharedWith}
                                isShared={true}
                            />
                        :
                            <></>
                    }
                    {
                        advShareModal && path === '/' ?
                            <ShareModal
                                open={advShareModal}
                                onClose={() => this.onAdvShareModal(false)}
                                onSuccess={() => this.onAdvShareModal(false)}
                                files={checkedFiles}
                                store={this.store} 
                                checked={checked}
                                sharedWith={sharedWith}
                                sharedPage={true}
                            />
                        :
                        <></>
                    }
                    {
                        shareFileModal ?
                            <ProcessingModal 
                                open={shareFileModal} 
                                saving={sharingFiles} 
                                onClose={() => this.onShareModal(false)} 
                                onSuccess={() => this.onShareModalSuccess()}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage={ newLinkUploaded === true ? 'Your download link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                                errorMessage={errorMessage}
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        downloadModal ?
                            <ProcessingModal 
                                open={downloadModal} 
                                saving={downloadingFiles} 
                                onClose={() => this.onDownloadModal(false)} 
                                onSuccess={() => this.onDownloadModalSuccess()}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage="Your files has been processed and your download link is ready."
                                errorMessage={errorMessage}
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        newFolderModal ?
                            <NewFolderModal
                                open={newFolderModal}
                                saving={creatingFolder}
                                store={this.store} path={path}
                                onClose={() => this.onNewFolderModal(false)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                confirmationMessage="Create new folder"
                            />
                        :
                        <></>
                    }
                    {
                        copyFilesModal ?
                            <CopyFilesModal 
                                open={copyFilesModal} 
                                saving={copyingFilesModal} 
                                onClose={() => this.onCopyModal(false)} 
                                onSuccess={(newPath) => this.onCopyModalSuccess(newPath)}
                                path={path}
                                match={this.props.match}
                                files={checkedFiles}
                                loadingMessage="Please wait while we copy your files. It may take few minutes depending on size of the files."
                                successMessage="Copy your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        moveFilesModal ?
                            <MoveFilesModal 
                                open={moveFilesModal} 
                                saving={movingFilesModal} 
                                onClose={() => this.onMoveModal(false)} 
                                onSuccess={(newPath) => this.onMoveModalSuccess(newPath)}
                                path={path}
                                match={this.props.match}
                                files={checkedFiles}
                                loadingMessage="Please wait while we move your files. It may take few minutes depending on size of the files."
                                successMessage="Move your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        renameFileModal && checkedFile !== null ?
                            <RenameFileModal 
                                open={renameFileModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onRenameModal(false)} 
                                onSuccess={(newPath) => this.onRenameModalSuccess(newPath)}
                                file={checkedFile}
                                fileId={fileId}
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        newFileModal ?
                            <NewFileModal
                                open={newFileModal}
                                saving={creatingFile}
                                store={this.store} path={path}
                                extension={newFileExtension}
                                onClose={() => this.onNewFileModal(false)}
                                onSuccess={(fileName) => this.onNewFileModalSuccess(fileName)}
                                confirmationMessage="Create new file"
                                error={newFileError}
                                errorMessage={newFileErrorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        noteModal && checkedFile !== null ?
                            <NoteModal 
                                open={noteModal} 
                                saving={noteSavingModal} 
                                onClose={() => this.onNoteModal(false)} 
                                onSuccess={(note) => this.onNoteModalSuccess(note)}
                                file={checkedFile}
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        fileNoteModal && checkedFile !== null ?
                            <FileNoteModal 
                                open={fileNoteModal}
                                onClose={() => this.onFileNoteModal(false)}
                                file={checkedFile}
                                onEdit={() => this.onNoteModal(true)}
                                canEdit={modificationAllowed}
                            />
                        :
                            <></>
                    }
                </div>
                {
                    officeFileModal ?
                        <OfficeFileModal 
                            store={this.store}
                            open={officeFileModal} 
                            action={officeFileAction}
                            file={checkedFile}
                            sharedFileId={fileId}
                            onClose={() => this.onOfficeFileModal(null, false)}
                        />
                    :
                    null
                }
                {
                    imagePreviewModal ?
                        <ImagePreviewModal
                            open={imagePreviewModal}
                            onClose={() => this.onImagePreviewModal(false)}
                            file={checkedFile}
                            onDownload={() => this.onDownloadModal(true)}
                        />
                    :
                    <></>
                }
                {
                    pdfFileModal ?
                        <PdfFileModal 
                            store={this.store}
                            open={pdfFileModal} 
                            file={checkedFile}
                            onClose={() => this.onPdfFileModal(false)}
                        />
                    :
                    null
                }
                {
                    noPreviewModal ?
                        <NoPreviewModal 
                            store={this.store}
                            open={noPreviewModal} 
                            onDownload={() => this.onDownloadModal(true)}
                            onClose={() => this.onNoPreviewModal(false)}
                        />
                    :
                    null
                }
                {
                    activitiesModal ?
                        <ActivitiesModal
                            store={this.store}
                            open={activitiesModal}
                            onClose={() => this.onActivityModal(false)}
                            activities={activities}
                            activitiesFileId={activitiesFileId}
                        />
                    :
                        <></>
                }
                <ContextMenu id="file_dropdown_menu" onShow={(e) => this.onContextMenuShow(e)}>
                    {
                        helper.hasThumbnail(checkedFile) ?
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('imagePreview', data, collect)}>
                                Preview
                            </MenuItem>
                        :   
                            <></>
                    }
                    {
                        helper.isPdfDocument(checkedFile) ?
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('pdfPreview', data, collect)}>
                                Preview
                            </MenuItem>
                        :   
                            <></>
                    }
                    {
                        Config.isWopiAllowed() ?
                            helper.isOfficeDocument(checkedFile) && user.extensions.ms_office ?
                                <>
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('view', data, collect)}>
                                        <Button color="transparent" justIcon className={classes.fileIcon}>
                                            <img src={officeIcon} alt={"folder"} />
                                        </Button>
                                        Preview
                                    </MenuItem>
                                    {
                                        canEdit ?
                                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('edit', data, collect)}>
                                                <Button color="transparent" justIcon className={classes.fileIcon}>
                                                    <img src={officeIcon} alt={"folder"} />
                                                </Button>
                                                Open in Microsoft Office for the web
                                            </MenuItem>
                                        :
                                            null
                                    }
                                </>
                            :
                                null
                        :
                            null
                    }
                    {
                        path === '/' && ownerId === user.id && sharedWith !== null && sharedWith.length > 0 ?
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('permission', data, collect)}>
                                Permissions
                            </MenuItem>
                        :
                            <></>
                    }
                    {
                        modificationAllowed ?
                            <>
                                {
                                    user.level_id !== 5 ?
                                        <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('share', data, collect)}>
                                            Share
                                        </MenuItem>
                                    :
                                        <></>
                                }
                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('delete', data, collect)}>
                                    Delete
                                </MenuItem>
                            </>
                        :
                        <></>
                    }
                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('download', data, collect)}>
                        Download
                    </MenuItem>
                    {
                        modificationAllowed ?
                        <>
                            {
                                user.level_id !== 5 && path !== '/' ?
                                    <>
                                        <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('copy', data, collect)}>
                                            Copy
                                        </MenuItem>
                                        <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('move', data, collect)}>
                                            Move
                                        </MenuItem>
                                    </>
                                :
                                   <></>
                            }
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('rename', data, collect)}>
                                Rename
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('note', data, collect)}>
                                {
                                    currentFile !== null && currentFile.note && currentFile.note !== null ?
                                        <>Edit note</>
                                    :
                                        <>Add a note</>
                                }
                            </MenuItem>
                        </>
                        :
                        <></>
                    }
                </ContextMenu>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={showError}
                    autoHideDuration={10000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <div className={classes.snackErrorMessage}>{this.renderErrorMessages()}</div>
                    }
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    {
                        officeFileModal === false ?
                            <link rel="shortcut icon" href="favicon.ico" />
                        :
                        null
                    }
                </Helmet>
                {
                    fileId ?
                        <form ref={this.editDocRef} target='_blank' method='GET' style={{display: 'none'}}>
                            {/* <input ref={this.editDocTokenRef} name="oauth-token" type="hidden" /> */}
                            <input name="sharedFile" type="hidden" value={fileId} />
                        </form>
                    :
                        <></>
                }
            </div>
        )
    }
}

UserShared.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserShared);
