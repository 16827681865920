import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from "react-router-dom";
import { createBrowserHistory } from 'history';
import moment from 'moment';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Sidebar from "../../components/User/Sidebar";
import Button from "../../components/CustomButtons/Button";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../../components/User/DeleteFileModal";
import UploadFileModal from "../../components/User/UploadFileModal";
import ProcessingModal from "../../components/User/ProcessingModal";
import NewFolderModal from "../../components/User/NewFolderModal";
import CopyFilesModal from "../../components/User/CopyFilesModal";
import MoveFilesModal from "../../components/User/MoveFilesModal";
import RenameFileModal from "../../components/User/RenameFileModal";
import ShareModal from "../../components/User/ShareModal";
import NewLinkModal from "../../components/User/NewLinkModal";
import SearchInput from "../../components/User/SearchInput";

import MaterialIcon from "@mdi/react";
import { mdiStar, mdiStarOutline, mdiLinkVariant, mdiTrashCanOutline } from '@mdi/js';
import foldericon from "../../../assets/img/foldericon.png";
import sharedFolderIcon from "../../../assets/img/sharedFolderIcon.png";
import ChevronRight from "@material-ui/icons/ChevronRight";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
const history = createBrowserHistory();

const UserArchives = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null" || path === "/" || path === "%2F"){
            path = "/Archives";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: false,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            downloadFile: false,
            file: null,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null,
            advShareModal: false,
            sharedWith: null,
            currentFile: null,
            downloadLink: null,
            linkName: false,
            searchFormData: this.defaultSearchFormData(),
            newUser: null,
            checkedAll: false,
            newLinkUploaded: false,
            saving: false,
            linkHash: null
        };

        this.loadingFolder = false;
        this.onShareModal = this.onShareModal.bind(this);
        this.onShareModalSuccess = this.onShareModalSuccess.bind(this);
        this.onAdvShareModal = this.onAdvShareModal.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            const location = this.history.location;
            let searchFormData = null;
            if(location.state){
                searchFormData = location.state;
            }
            if(searchFormData !== null){
                this.onSearchInputSuccess(searchFormData.searchFormData);
            }else{
                this.loadFiles();
            }
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultSearchFormData(){
        return {
            fileName: '',
            searchIn: '',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
        }
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadFiles()
        });
    }
    onSearchInputSuccess(searchData){
        const location = this.history.location;
        const pathname = location.pathname;

        if(pathname === '/user/archives' && searchData.searchIn === 'link-manager'){
            this.history.push({
                pathname: '/user/links',
                search: '',
                state: { searchFormData: searchData }
            });
            return;
        }

        let backPath = '';
        if(searchData.searchIn === 'web'){
            backPath = '/Email/Web';
        }else if(searchData.searchIn === 'docs'){
            backPath = '/My Docs';
        }else if(searchData.searchIn === 'email'){
            backPath = '/';
        }

        this.setState({
            searchFormData: {
                fileName: searchData.fileName,
                searchIn: searchData.searchIn,
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadFiles(false, null, backPath);
        });
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, favourites, searchFormData } = this.state;
        if(backPath === '/'){
            return true;
        }
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            archives: 1
        };

        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }

        // search for request data
        if(searchFormData.fileName){
            requestData['name'] = searchFormData.fileName;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }

        backPath = this.getBackPath(path);
        history.push("/user/archives?dir="+encodeURIComponent(path));
        Api.getFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.favourite){
                    favourites.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                favourites: favourites
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        let newState = {
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false,
            newFolderModal: false,
            creatingFolder: false,
            copyFilesModal: false,
            copyingFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null
        };
        if(!viewMore){
            newState['totalPages'] = 0;
        }
        this.setState(newState);
        this.loadingFolder = true;
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    handleToggle(file) {
        if(this.loadingFolder || file.id === 0){
            return;
        }
        // if(file.default){
        //     return;
        // }
        
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null)
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/archives" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
                recipients: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            let fileArray = {
                id: file.id,
                default: file.default,
                type: file.type,
                downloadLink: file.downloadLink,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalName: file.name,
                name: this.getFileName(file),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.date,
                owner: file.owner,
                sender_email: (
                    <div title={ file.sender_email }>
                        { file.sender_email }
                    </div>
                ),
                recipients: (
                    <>
                        {
                            file.title !== '' && file.title ?
                                file.title
                            :
                                file.recipients.join(",")
                        }
                    </>
                ),
                expire_at: (
                    <div>
                        {this.getFormatedExpiry(file.expire_at)}
                    </div>
                ),
                last_downloaded: this.getFormatedDownloadDate(file.download_date),
                relatedLinks: this.getRelatedLink(file)
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        return moment(expire_at).format("MM/DD/YYYY h:mm A");
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    getFileName(file){
        const { classes } = this.props;
        const { favourites } = this.state;
        return (
            <div title={file.name}>
                <Button justIcon color="transparent" className={classes.fileIcon} onClick={() => this.favourite(file)}>
                    {
                        favourites.includes(file.id) ?
                            <MaterialIcon path={mdiStar} className="MuiSvgIcon-root star_filled" />
                        :
                        <MaterialIcon path={mdiStarOutline} className="MuiSvgIcon-root star_outlined" />
                    }
                </Button>
                {
                    file.type === "folder" ?
                        <Link href="/user/archives" onClick={(e) => this.loadFolder(e, file)}>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        <img src={sharedFolderIcon} alt={"folder"} />
                                    :
                                        <img src={foldericon} alt={"folder"} />
                                }
                            </Button>
                            {file.name}
                        </Link>
                    :
                    <>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            {" "}
                        </Button>
                        {file.name}
                    </>
                }
            </div>
        )
    }
    getRecipients(file){
        if(file.transfer_type === "share"){
            return <div>Anyone (links only)</div>;
        }
        if(file.recipients.length <= 2){
            return <div>{file.recipients.join(",")}</div>;
        }
        const firstTwoRecipients = file.recipients.slice(0, 2);
        let recipientsList = [];
        file.recipients.map((email, key) => {
            recipientsList.push(
                <li key={key}>{email}</li>
            );
            return null;
        });
        return (
            <>
                <div>
                    {firstTwoRecipients.join(",")}
                </div>
                <ul>
                    {recipientsList}
                </ul>
            </>
        );
    }
    getRelatedLink(file){
        if(file.relatedLinks.length <= 0){
            return null;
        }
        const url = "/user/links?page=1&links="+file.relatedLinks.join(",");
        return (
            <RouterLink to={url}>
                <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
            </RouterLink>
        );
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.setState({path: parent.path}, () => {
            this.loadFiles(false);
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { backPath } = this.state;
        if(backPath.length <= 0){
            return {};
        }
        if (rowInfo && rowInfo.row) {
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false,
            checkedAll: false,
        });
    }
    onModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        Api.deleteFiles(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                deleteAll: false,
                checkedAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    onUploadModal(uploadFileModal = true){
        if(!uploadFileModal){
            this.loadFiles(false);
            return;
        }
        this.setState({
            uploadFileModal: uploadFileModal,
        });
    }
    onUploadModalSuccess(){
        this.loadFiles(false);
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
    
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        });
    }
    onLinkName(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        this.setState({
            linkName: status,
            shareFileModal: status,
        });
    }
    onShareModal(status = false, linkTitle = null, linkPassword = null){
        const { checked, path } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
            path: path
        };
        if(typeof(path) === "string" && path.indexOf("/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        if(linkTitle !== null){
            requestData['linkTitle'] = linkTitle;
        }
        if(linkPassword !== null){
            requestData['linkPassword'] = linkPassword;
        }
        
        Api.shareFiles(requestData, source).then(data => {
            if(data.status === 'uploaded'){
                this.setState({
                    sharingFiles: false,
                    newLinkUploaded: true
                })

                return;
            }
            
            let downloadLink = "/downloads/"+data.hash;
            this.setState({sharingFiles: false, downloadLink: downloadLink, linkHash: data.hash});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message, sharingFiles: false});
            }
        });
        this.setState({
            shareFileModal: status,
            sharingFiles: status,
            cancelToken: source,
            linkName: false
        });
    }
    onAdvShareModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        this.setState({
            advShareModal: status,
        });
        if(!status){
            this.loadFiles();
        }
    }
    onShareModalSuccess(){
        this.setState({
            shareFileModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false
        });
        this.loadFiles();

        // const { downloadUrl } = this.state;
        // if(downloadUrl.length > 0){
        //     window.location.href = downloadUrl;
        // }
        // this.history.push("/user/links");
    }
    onDownloadModal(status = false){
        const { checked, path, downloadFile, file } = this.state;
        if(checked.length <= 0){
            return;
        }

        if(downloadFile && checked.length === 1 && file){
            window.location.href = file.downloadLink;
            return;
        }

        let source = null;
        if(status){
            source = axios.CancelToken.source();
            const requestData = {
                files: checked,
                path: path
            };
            Api.downloadFiles(requestData, source).then(data => {
                this.setState({downloadingFiles: false, downloadUrl: data.downloadUrl});
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({cancelToken: null, errorMessage: err.message, downloadingFiles: false});
                }
            });
        }

        this.setState({
            downloadModal: status,
            downloadingFiles: status,
            cancelToken: source
        });
    }
    onDownloadModalSuccess(){
        this.setState({
            downloadModal: false,
            errorMessage: null
        });
        const { downloadUrl } = this.state;
        if(downloadUrl.length > 0){
            window.location.href = downloadUrl;
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Archives"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        if(pathArray.length > 0){
            breadcrumbs = [];
        }
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status,
        });
    }
    onNewFolderModalSuccess(folderName){
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });

        const requestData = {
            folderName: folderName,
            path: this.state.path
        }
        Api.addNewFolder(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            console.log(err);
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        )

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Sender",
                accessor: "sender_email",
                headerClassName: "hd_sender",
                className: "hd_sender td_sender",
            },
            {
                Header: "Destination",
                accessor: "recipients",
                headerClassName: "hd_recipients",
                className: "hd_recipients td_recipients",
            },
            {
                Header: "Expiry Date",
                accessor: "expire_at",
                headerClassName: "hd_expire",
                className: "hd_expire td_expire",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Last Downloaded",
                accessor: "last_downloaded",
                headerClassName: "hd_last_download",
                className: "hd_last_download td_last_download",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Related Links",
                accessor: "relatedLinks",
                headerClassName: "hd_related_links",
                className: "hd_related_links td_creation_date",
            }
        ];
        return columns;
    }
    onContextMenuItemClick(type, data){
        switch(type){
            case 'share':
                this.onShareModal(true);
                break;
            case 'delete':
                this.deleteSelected();
                break;
            case 'download':
                this.onDownloadModal(true);
                break;
            case 'copy':
                this.onCopyModal(true);
                break;
            case 'move':
                this.onMoveModal(true);
                break;
            case 'rename':
                this.onRenameModal(true);
                break;
            case 'advShare':
            case 'permission':
                this.onAdvShareModal(true);
                break;
            default:
                break;
        }
    }
    onContextMenuShow(e){
        const { checked } = this.state;
        if(checked.length > 1){
            return;
        }
        const file = e.detail.data;
        this.setState({
            checked: [file.id],
            sharedWith: (file ? file.sharedWith : null),
            currentFile: (file ? file : null),
        });
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    onCopyModal(modal = true){
        this.setState({
            copyFilesModal: modal,
            errorMessage: null
        });
    }
    onCopyModalSuccess(newPath){
        const source = axios.CancelToken.source();
        this.setState({
            copyingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        Api.copyFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    copyingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onMoveModal(modal = true){
        this.setState({
            moveFilesModal: modal,
            errorMessage: null
        });
    }
    onMoveModalSuccess(newPath){
        const source = axios.CancelToken.source();
        this.setState({
            movingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        Api.moveFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    movingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onRenameModal(modal = true){
        this.setState({
            renameFileModal: modal,
            errorMessage: null
        });
    }
    onRenameModalSuccess(newFileName){
        const checkedFile = this.getCheckedFile();
        if(checkedFile === null){
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            renamingFileModal: true,
            cancelToken: source,
        });
        const requestData = {
            name: newFileName,
        };
        Api.renameFile(checkedFile.id, requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err && err.duplicate === true){
                this.setState({cancelToken: null, errorMessage: err.message, renamingFileModal: false});
            }
            console.log(err);
        });
    }
    getCheckedFile(checked = null){
        const { response } = this.state;
        if(checked === null){
            checked = this.state.checked;
        }
        if(checked.length <= 0){
            return null;
        }
        let currentFile = null;
        response.map((file) => {
            if(currentFile !== null){
                return null;
            }
            if(checked.indexOf(file.id) !== -1){
                currentFile = file;
            }
            return null;
        });
        return currentFile;
    }
    onDrop(filesArray){
        this.setState({
            files: filesArray,
            uploadFileModal: true
        });
    }
    onRelatedLinks(){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        const url = "/user/links?page=1&files="+checked.join(",");
        this.history.push(url);
    }
    getFilesContent(files){
        const { classes } = this.props;
        const { loading, loadingMore, page, totalPages, checked, searchFormData, response, totalItemCount, backPath } = this.state; 
        const breadcrumbs = this.getBreadCrumbs();
        const tableData = this.getTableData();
        const { user } = this.store.getState();

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem>
                        <GridContainer className={classes.alignItems}>
                            <GridItem xs={12} sm={6} md={8}>
                                <h4>Archives</h4>
                            </GridItem>
                            <GridItem xs={12} sm={6} md={4}>                                
                                <SearchInput searchFormData={searchFormData} onSuccess={(searchData) => this.onSearchInputSuccess(searchData)} clearSearch={() => this.clearSearch()} history={this.history} store={this.store} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                        {
                            checked.length > 0 ?
                                <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.deleteSelected()}>
                                    <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                    Delete
                                </Button>
                            :
                                <></>
                        }
                    </div>
                </div>
                <div className={classes.breadcrumbs}>
                    <div>
                        <img src={foldericon} alt={"folder"} />
                        {
                            breadcrumbs.map((breadCrumb, key) => {
                                return (
                                    <div key={key}>
                                        {
                                            key !== 0 ?
                                                <span><ChevronRight /></span>
                                            :
                                            <></>
                                        }
                                        <Link href="/user/archives" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, breadCrumb.value);}}>
                                            {breadCrumb.label}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        !loading && response !== null ?
                            <div className={classes.itemCount}>
                                {
                                    totalItemCount === 1 ?
                                        totalItemCount+" item"
                                    :
                                        totalItemCount+" items"
                                }
                            </div>
                        :
                        <></>
                    }
                </div>
                <div className={classes.filesContainer+" "+classes.archivesContainer}>
                    <div className={classes.files}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            null
                        }
                        <ReactTable
                            columns={this.getColumns()}
                            data={tableData}
                            pageSizeOptions={[500]}
                            defaultPageSize={50000000000}
                            showPaginationTop={false}
                            minRows={0}
                            showPaginationBottom={false}
                            className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable+" "+classes.archivesTable+" "+(backPath.length > 0 ? classes.stickyRow : "")}
                            getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                            getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                            getNoDataProps={() => this.getNoDataProps()}
                        />
                    </div>
                </div>
                {
                    loading || loadingMore ?
                        <></>
                    :
                        <ul className={classes.linkFilters+" "+classes.fileActions}>
                            <li className={(checked.length >= 2 ? classes.fileActionEnabled : "")}>
                                <Button color="transparent" onClick={() => this.onRelatedLinks(true)}>
                                    <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
                                    <span>Related Links</span>
                                </Button>
                            </li>
                        </ul>
                }
                <div className={classes.loadMore}>
                    {
                        tableData.length > 0 && loadingMore === false && page < totalPages ?
                            <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                <RefreshIcon className={classes.icons} />View More
                            </Button>
                            
                        :
                            loadingMore === true ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <></>
                    }
                </div>
            </div>
        );
        
        return (
            content 
        );
    }
    render() {
        const { classes } = this.props;
        const { deleteFileModal, uploadFileModal, path, shareFileModal,
            downloadModal, downloadingFiles, newFolderModal, creatingFolder, copyFilesModal, copyingFilesModal,
            moveFilesModal, movingFilesModal, renameFileModal, renamingFileModal, files, errorMessage, checked, sharingFiles,
            advShareModal, sharedWith, downloadLink, linkName, newLinkUploaded, saving } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        
        const checkedFiles = this.getCheckedFiles();
        const checkedFile = this.getCheckedFile();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    {
                        this.getFilesContent(files)
                    }
                    <DeleteFileModal
                        deletedFiles={checkedFiles}
                        open={deleteFileModal}
                        onClose={() => this.onModalClose(false)}
                        onSuccess={() => this.onModalSuccess()}
                        loading={saving}
                        errorMessage={errorMessage}
                    />
                    {
                        uploadFileModal ?
                            <UploadFileModal open={uploadFileModal} store={this.store} path={path} files={files} onClose={() => this.onUploadModal(false)} onSuccess={() => this.onUploadModalSuccess()} />
                        :
                            <></>
                    }
                    {
                        advShareModal &&  path.indexOf("/My Docs") !== -1 ?
                            <ShareModal
                                open={advShareModal}
                                onClose={() => this.onAdvShareModal(false)}
                                onSuccess={() => this.onAdvShareModal(false)}
                                files={checkedFiles}
                                store={this.store} 
                                checked={checked}
                                sharedWith={sharedWith}
                                onPermissionsUpdate={this.onPermissionsUpdate}
                            />
                        :
                        <></>
                    }
                    {
                        shareFileModal ?
                            <NewLinkModal 
                                open={shareFileModal} 
                                saving={sharingFiles} 
                                onClose={() => this.onLinkName(false)} 
                                onSuccess={() => this.onShareModalSuccess()}
                                onLinkName={(name, linkPassword) => this.onShareModal(true, name, linkPassword)}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage={ newLinkUploaded === true ? 'Your download link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                                errorMessage={errorMessage}
                                downloadLink={downloadLink}
                                linkName={linkName}
                                store={this.store}
                                linkHash={this.state.linkHash}
                            />
                        :
                        <></>
                    }
                    {
                        downloadModal ?
                            <ProcessingModal 
                                open={downloadModal} 
                                saving={downloadingFiles} 
                                onClose={() => this.onDownloadModal(false)} 
                                onSuccess={() => this.onDownloadModalSuccess()}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage="Your file(s) has been processed and your download link is ready."
                                errorMessage={errorMessage}
                                store={this.store} 
                            />
                        :
                        <></>
                    }
                    {
                        newFolderModal ?
                            <NewFolderModal
                                open={newFolderModal}
                                saving={creatingFolder}
                                store={this.store} path={path}
                                onClose={() => this.onNewFolderModal(false)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                confirmationMessage="Create new folder"
                            />
                        :
                        <></>
                    }
                    {
                        copyFilesModal ?
                            <CopyFilesModal 
                                open={copyFilesModal} 
                                saving={copyingFilesModal} 
                                onClose={() => this.onCopyModal(false)} 
                                onSuccess={(newPath) => this.onCopyModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we copy your files. It may take few minutes depending on size of the files."
                                successMessage="Copy your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        moveFilesModal ?
                            <MoveFilesModal 
                                open={moveFilesModal} 
                                saving={movingFilesModal} 
                                onClose={() => this.onMoveModal(false)} 
                                onSuccess={(newPath) => this.onMoveModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we move your files. It may take few minutes depending on size of the files."
                                successMessage="Move your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        renameFileModal && checkedFile !== null ?
                            <RenameFileModal 
                                open={renameFileModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onRenameModal(false)} 
                                onSuccess={(newPath) => this.onRenameModalSuccess(newPath)}
                                file={checkedFile}
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserArchives.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserArchives);
