import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from "react-router-dom";
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import ApiOneDrive from "../../../assets/js/utils/oneDrive/Api.js";
import ApiSharepoint from "../../../assets/js/utils/Sharepoint/Api.js";
import Auth from "../../../assets/js/utils/Auth.js";
import Cookie from "../../../assets/js/utils/Cookie.js";
import { helper } from "../../../assets/js/utils/Element.js";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider.jsx";
import Sidebar from "../../components/User/Sidebar.js";
import Button from "../../components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader.js";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteFileModal from "../../components/User/DeleteFileModal.jsx";
import UploadFileModal from "../../components/User/UploadFileModal.jsx";
import ProcessingModal from "../../components/User/ProcessingModal.js";
import NewFolderModal from "../../components/User/NewFolderModal.js";
import CopyFilesModal from "../../components/User/CopyFilesModal.js";
import CopyMyDocModal from '../../components/User/CopyMyDocModal';
import MoveFilesModal from "../../components/User/MoveFilesModal.js";
import RenameFileModal from "../../components/User/RenameFileModal.js";
import ShareModal from "../../components/User/ShareModal.js";
import NewLinkModal from "../../components/User/NewLinkModal.js";
import SearchInput from "../../components/User/SearchInput.js";
import FileVersionModal from "../../components/User/FileVersionModal.jsx";
import NewFileModal from "../../components/User/NewFileModal.js";
import ImagePreviewModal from "../../components/User/imageModal.js";
import ListIcon from '@material-ui/icons/FormatListBulleted';
import GridIcon from '@material-ui/icons/ViewComfy';
import EditIcon from '@material-ui/icons/Edit';
import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiArrowCollapseDown, mdiTrashCanOutline, mdiStar, mdiStarOutline, mdiLinkVariant } from '@mdi/js';
import newlinkicon from "../../../assets/img/New_Link.png";
import newlinkiconLight from "../../../assets/img/New_Link-light.png";
import relatedLinksIcon from "../../../assets/img/related_links.png";
import relatedLinksIconLight from "../../../assets/img/related_links-light.png";
import foldericon from "../../../assets/img/foldericon.png";
import sharedFolderIcon from "../../../assets/img/sharedFolderIcon.png";
import ChevronRight from "@material-ui/icons/ChevronRight";
import newFolderIcon from "../../../assets/img/new_folder.png";
import newFolderIconLight from "../../../assets/img/new_folder-light.png";
import newOfficeDoc from "../../../assets/img/new_office_doc.png";
import newOfficeDocLight from "../../../assets/img/new_office_doc-light.png";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DragNDrop from "../../components/Drag/Drop.jsx";
import OfficeFileModal from "../../components/User/OfficeFileModal.jsx";
import officeIcon from "../../../assets/img/office.svg";
import PdfFileModal from "../../components/User/PdfFileModal.jsx";
import NoPreviewModal from "../../components/User/NoPreviewModal.js";
import Menu from '@material-ui/core/Menu';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import ConfirmationModal from "../../components/User/ConfirmationModal.js";
import DetailsSection from "../../components/User/DetailsSection.js";
import withStyles from "@material-ui/core/styles/withStyles";
import Config from '../../../../Config.js';
// import SyncIcon from '@material-ui/icons/Sync';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SignModal from "../../components/Docusign/SignModal.jsx";
import onedriveIcon from "../../../assets/img/cloudicon.png";
import onedriveIconLight from "../../../assets/img/cloudicon-light.png";
import NewEditAccountModal from '../../components/User/oneDrive/NewEditAccountModal';
import SharepointAccountModal from "../../components/User/Sharepoint/SharepointAccountModal";

import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const history = createBrowserHistory();

const UserHome = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);
        this.editDocRef = React.createRef();
        this.editDocTokenRef = React.createRef();
        this.editDocSecretRef = React.createRef();

        const { customState } = this.store.getState();

        let files = [];
        let sharedWithUsers = [];
        let shareFileId = '';
        let resetComponent = false;
        let uploadFileModal = false;
        if(customState !== null && customState.hasOwnProperty("type") && customState.type === 'upload'){
            files = customState.hasOwnProperty("files") ? customState.files : files;
            sharedWithUsers = customState.hasOwnProperty("sharedWithUsers") ? customState.sharedWithUsers : sharedWithUsers;
            shareFileId = customState.hasOwnProperty("shareFileId") ? customState.shareFileId : shareFileId;
            uploadFileModal = true;
            resetComponent = true;
        }

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            responseNew: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: uploadFileModal,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            downloadFile: false,
            file: null,
            newFolderModal: false,
            creatingFolder: false,
            copyMyDocModal: false,
            copyingMyDocsFilesModal: false,
            actionFlag: "CopyToMyDocs",
            copyFilesModal: false,
            copyingFilesModal: false,
            copyingFilesError: null,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            firstTimeLoginModal: false,
            files: files,
            errorMessage: null,
            advShareModal: false,
            sharedWith: null,
            currentFile: null,
            downloadLink: null,
            linkName: false,
            searchFormData: this.defaultSearchFormData(),
            newUser: null,
            shareFileId: shareFileId,
            fileCopyStatus: '',
            sharedWithUsers: sharedWithUsers,
            checkedAll: false,
            versionsModal: false,
            newLinkUploaded: false,
            officeFileModal: false,
            officeFileAction: "view",
            docMenuEl: null,
            newFileModal: false,
            newFileExtension: null,
            creatingFile: false,
            newFileError: false,
            newFileErrorMessage: null,
            imagePreviewModal: false,
            layoutType: 'list',
            pdfFileModal: false,
            detailsSection: false,
            saving: false,
            linkHash: null,
            activeTab: 0,
            oneDriveEmail :null,
            isOneDriveFolder :false,
            oldCheckedFile:[],
            oldCheckedFilePath: [],
            newDataPush:[],
            processedFileIds:[],
            folderParentIdOneDrive:null,
            folderParentPathOneDrive:null,
            newFolderModalOneDrivePath:null,
            newFolderModalOneDrive:false,
            docusignModal: false,
            filesCopiedSize: 0,
            canceledCopyingMyDocsFilesModal: false,
            resetComponent: resetComponent,
            newEditAccountModal: false,
            sharepointAccountModal: false
        };

        this.loadingFolder = false;
        this.onShareModal = this.onShareModal.bind(this);
        this.onShareModalSuccess = this.onShareModalSuccess.bind(this);
        this.onAdvShareModal = this.onAdvShareModal.bind(this);

    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+decodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.login === 0){
                localStorage.setItem("show_first_view_shared", (user.login === 0));
            }
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            if(window.location.pathname === "/user/home/connect/docusign"){
                this.onDocusignConnectCallback();
                return;
            }else{
                let oldState = localStorage.getItem("sendlinx_home_state");
                if(oldState !== null){
                    oldState = JSON.parse(oldState);
                    this.setState(oldState);
                    return;
                }
            }

            if(!this.state.resetComponent){
                const location = this.history.location;
                let searchFormData = null;
                if(location.state){
                    searchFormData = location.state;
                }
                if(searchFormData !== null){
                    this.onSearchInputSuccess(searchFormData.searchFormData);
                }else{
                    this.loadFiles();
                }
            }
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    defaultSearchFormData(){
        return {
            search: '',
            fileName: '',
            searchIn: '',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
        }
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadFiles()
        });
    }
    onSearchInputSuccess(searchData){
        const location = this.history.location;
        const pathname = location.pathname;

        if(pathname === '/user/home' && searchData.searchIn === 'link-manager'){
            this.history.push({
                pathname: '/user/links',
                search: '',
                state: { searchFormData: searchData }
            });
            return;
        }

        let backPath = '';
        if(searchData.searchIn === 'web'){
            backPath = '/Email/Web';
        }else if(searchData.searchIn === 'docs'){
            backPath = '/My Docs';
        }else if(searchData.searchIn === 'email'){
            backPath = '/';
        }

        this.setState({
            searchFormData: {
                search: searchData.search,
                fileName: searchData.fileName,
                searchIn: searchData.searchIn,
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadFiles(false, null, backPath);
        });
    }
    loadOneDriveNested(Id,folderId){
        const { path } = this.state;
        const { user } = this.store.getState();
        if(user.extensions.one_drive.status === 0){
            return true
        }
        this.setState({
            getOneDriveNestedFiles:true
        })
        let isSharePoint = false;
        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            isSharePoint = true;
            apiUrl = ApiSharepoint;
        }
        apiUrl.getOneDriveNestedFiles(Id,folderId).then(dataRe => {
            const getFolderPath = (fullPath) => {
                const path = fullPath;
                const dataAfterRoot = path.split("root:")[1];
                let returnFullPath = decodeURIComponent("/OneDrive"+dataAfterRoot);
                if(isSharePoint){
                    returnFullPath = decodeURIComponent("/Sharepoint"+dataAfterRoot);
                }
                return returnFullPath;
            };
            const getFolderPathOneDrive = (fullPath) => {
                const path = fullPath;
                const dataAfterRoot = path.split("root:")[1];
                return dataAfterRoot;
            };
            const odataContext1 = dataRe["@odata.context"];
            const emailRegex = /'([^']+)'/;
            const match = odataContext1.match(emailRegex);
            if (match && match.length > 1) {
                const email = decodeURIComponent(match[1]);
                this.setState({
                    oneDriveEmail: email
                })
            } 
            const data = dataRe.value;
            const getFileExtension = (filename) => {
                const parts = filename.split('.');
                return parts[parts.length - 1];
            };
            const convertBitsToKbMb = (sizeInBits) => {
                let customeSize;
                const sizeInKb = (sizeInBits / 1024).toFixed(2);
                customeSize = sizeInKb +' kb';
                if (sizeInKb >= 1024) {
                    const sizeInMb = (sizeInKb / 1024).toFixed(2); 
                    customeSize = sizeInMb +' mb';
                }
                return customeSize;
            };
            const newData =[]
            for (let index = 0; index < data.length; index++) {
                const file = data[index];
                const downloadUrl = file['@microsoft.graph.downloadUrl'];
                let folderPath = "/OneDrive";
                let owner = "OneDrive";
                let transferType = "one_drive";
                if(isSharePoint){
                    folderPath = "/Sharepoint";
                    owner = "Sharepoint";
                    transferType = "sharepoint";
                }
                newData.push({
                    "id":data[index].id,
                    "parent_id":data[index].parentReference.driveId,
                    "parent_id_drive":data[index].parentReference.id,
                    "type":data[index]?.folder ? 'folder' : 'file',
                    "name":data[index].name,
                    "orignalSize":data[index].size,
                    "size":convertBitsToKbMb(data[index].size),
                    "date":data[index].createdDateTime,
                    "modified_date":data[index].lastModifiedDateTime,
                    "owner": owner,
                    "favourite":false,
                    "default":0,
                    "downloadLink":downloadUrl,
                    "recipients":[
                    ],
                    "transfer_type": transferType,
                    "pathOneDrive":decodeURIComponent(getFolderPathOneDrive(data[index]?.parentReference?.path)),
                    "path":decodeURIComponent(getFolderPath(data[index]?.parentReference?.path)+"/"+data[index].name),
                    "folder_path": folderPath,
                    "relatedLinks":[
                    ],
                    "sharedWith":[
                    ],
                    "versionControl":null,
                    "mime_major":data[index]?.file?.mimeType,
                    "extension":getFileExtension(data[index].name),
                    "created_at":data[index].createdDateTime,
                    "updated_at":data[index].lastModifiedDateTime,
                    "folder_id":data[index]?.parentReference?.driveId,
                    "last_modified_by":"",
                    "thumbnails":{
                        'large': downloadUrl,
                        'medium': downloadUrl,
                        'small': downloadUrl
                    },
                    "webLink":data[index].webUrl,
                })
            }
            this.setState({
                totalItemCount: newData.length,
                responseNew: newData,
                response: newData,
                loading: false,
                loadingMore: false,
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        let IsoneDriveParent = false;
        if(backPath){
            IsoneDriveParent = true;
        }
        const source = axios.CancelToken.source();
        let { page, path, favourites, searchFormData, shareFileId } = this.state;
        if(backPath === '/'){
            return true;
        }
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200
        };

        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }

        // search for request data
        if(searchFormData.search){
            requestData['name'] = searchFormData.search;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }

        let fileIdParam = "";
        let fileId = '';
        if(helper.getParam("fileId") !== '' && helper.getParam("fileId") !== 'null'){
            fileId = helper.getParam("fileId");
            fileIdParam = "&fileId="+fileId;
        }
        if(shareFileId !== ''){
            fileIdParam = "&fileId="+shareFileId;
            fileId = shareFileId;
        }
        if(path === "/My Docs" || path === "/" || path === "/OneDrive" || path === "OneDrive" || path === "/Sharepoint" || path === "Sharepoint"){
            fileIdParam = "";
            fileId = '';
        }
        this.setState({
            shareFileId: fileId
        })
        if(fileId !== ''){
            requestData['shareFileId'] = fileId;
        }

        if(path === '/'){
            path = '/My Docs';
        }

        requestData['storageTransfers'] = false;
        if(path.indexOf("/Email/Web") !== -1 || path.indexOf("/Email/Outlook") !== -1){
            requestData['storageTransfers'] = true;
        }

        backPath = this.getBackPath(path);
        history.push("/user/home?dir="+decodeURIComponent(path)+fileIdParam);

        if(path.includes("/OneDrive") || path.includes("/Sharepoint")){
            let isSharePoint = false;
            if(path.includes("/Sharepoint")){
                isSharePoint = true;
            }
            if (path === "/OneDrive" || path === "OneDrive" || path === "/Sharepoint" || path === "Sharepoint"){
                let apiUrl = ApiOneDrive;
                if(isSharePoint){
                    apiUrl = ApiSharepoint;
                }
                const { user } = this.store.getState();
                if(user.extensions.one_drive.status === 0 && user.extensions.sharepoint.status === 0){
                    return true
                }
                
                apiUrl.getDriveFiles().then( async dataRe =>  {
                    const getFolderPath = (fullPath) => {
                        const path = fullPath;
                        const dataAfterRoot = path.split("root:")[1];
                        let returnFullPath = decodeURIComponent("/OneDrive"+dataAfterRoot);
                        if(isSharePoint){
                            returnFullPath = decodeURIComponent("/Sharepoint"+dataAfterRoot);
                        }
                        return returnFullPath;
                    };
                    const getFolderPathOneDrive = (fullPath) => {
                        const path = fullPath;
                        const dataAfterRoot = path.split("root:")[1];
                        return dataAfterRoot;
                    };
                    const odataContext1 = dataRe["@odata.context"];
                    const emailRegex = /'([^']+)'/;
                    const match = odataContext1.match(emailRegex);
                    if (match && match.length > 1) {
                        const email = decodeURIComponent(match[1]);
                        this.setState({
                            oneDriveEmail: email
                        })
                        localStorage.setItem("one_drive_email", email);
                    }
                    const data = dataRe.value;
                    const getFileExtension = (filename) => {
                        const parts = filename.split('.');
                        return parts[parts.length - 1];
                    };
                    const convertBitsToKbMb = (sizeInBits) => {
                        let customeSize;
                        const sizeInKb = (sizeInBits / 1024).toFixed(2);
                        customeSize = sizeInKb +' kb';
                        if (sizeInKb >= 1024) {
                            const sizeInMb = (sizeInKb / 1024).toFixed(2); 
                            customeSize = sizeInMb +' mb';
                        }
                        return customeSize;
                    };
                    const newData =[];
                    const oldExistingId = [];
                    const oldExistingPath = [];
                    for (let index = 0; index < data.length; index++) {
                        const source = axios.CancelToken.source();
                        const requestData = {
                            itemId: data[index]?.id,
                        }
                        const file = data[index];
                        const downloadUrl = file['@microsoft.graph.downloadUrl'];
                        let folderPath = "/OneDrive";
                        let owner = "OneDrive";
                        let transferType = "one_drive";
                        if(isSharePoint){
                            folderPath = "/Sharepoint";
                            owner = "Sharepoint";
                            transferType = "sharepoint";
                        }
                        newData.push({
                            "fileCopyStatus" : false, 
                            "flagStatus" : false,                        
                            "id":data[index].id,
                            "parent_id":data[index].parentReference.driveId,
                            "parent_id_drive":data[index].parentReference.id,
                            "type":data[index]?.folder ? 'folder' : 'file',
                            "name":data[index].name,
                            "orignalSize":data[index].size,
                            "size":convertBitsToKbMb(data[index].size),
                            "date":data[index].createdDateTime,
                            "modified_date":data[index].lastModifiedDateTime,
                            "owner": owner,
                            "favourite":false,
                            "default":0,
                            "downloadLink":downloadUrl,
                            "recipients":[
                            ],
                            "transfer_type": transferType,                        
                            "path":getFolderPath(data[index]?.parentReference?.path)+"/"+data[index].name,
                            "pathOneDrive":getFolderPathOneDrive(data[index]?.parentReference?.path),
                            "folder_path": folderPath,
                            "relatedLinks":[
                            ],
                            "sharedWith":[
                            ],
                            "versionControl":null,
                            "mime_major":data[index]?.file?.mimeType,
                            "extension":getFileExtension(data[index].name),
                            "created_at":data[index].createdDateTime,
                            "updated_at":data[index].lastModifiedDateTime,
                            "folder_id":data[index]?.parentReference?.driveId,
                            "last_modified_by":"",
                            "thumbnails":{
                                'large': downloadUrl,
                                'medium': downloadUrl,
                                'small': downloadUrl
                            },
                            "webLink":data[index].webUrl,
                        })
                        if (data[index]?.id === true) {
                            await Api.getSingleFile(requestData, source).then(data => {
                                if(data.response.driveName ==='onedrive' && data.response.copyStatus === '1'){
                                    newData[index].fileCopyStatus = true;
                                    // const newPath = data.respons.storage_path.replace(/^uploads\/\d+\//, '/');
                                    const destPath = data.response.destinationPath + data.response.name
                                    oldExistingPath.push(destPath);
                                    oldExistingId.push(newData[index].id);
                                }else{
                                    newData[index].fileCopyStatus = false;
                                }
                                
                                if(data.response.flagStatus === 1){
                                    newData[index].flagStatus = true;
                                }else{
                                    newData[index].flagStatus = false;
                                }
                            }).catch(err => {
                                console.log(err);
                            });
                        }
                    }
                    this.setState({
                        totalItemCount: newData.length,
                        responseNew: newData,
                        oldCheckedFile:oldExistingId,
                        oldCheckedFilePath:oldExistingPath,
                        response: newData,
                        loading: false,
                        loadingMore: false,
                    });
                    this.loadingFolder = false;
                }).catch(err => {
                    if('notAllowed' === err || ('invalidRequest' === err && isSharePoint === true)){
                        let redirectUrl = '/user/extensions?showAccessPopup=true&type='+(isSharePoint ? 'sharepoint' : 'onedrive');
                        this.history.push(redirectUrl);

                        // alert('does not have a OneDrive account that is accessible.');
                    }else if ('add Account' === err) {
                        // alert('Please Add Account First!!')
                        var result = window.confirm("Please Add Account First!!");
                        if (result) {
                            this.history.push("/user/extensions");
                        }else{
                            this.history.push("/user/home?dir=/My Docs");
                        }
                        this.setState({
                            loading: false,
                        });
                    }
                    console.log(err);
                });
            }else{
                let MainIdM = null
                if(IsoneDriveParent){
                    MainIdM = localStorage.getItem("parent_id_drive");
                }else{
                    MainIdM =  localStorage.getItem("main_Id");
                }
                const folderId = localStorage.getItem("folder_Id");
                this.loadOneDriveNested(MainIdM,folderId);
            }
            if(isSharePoint){
                this.setState({activeTab:2});
            }else{
                this.setState({activeTab:1});
            }
        }else{          
            Api.getFiles(requestData, source).then(data => {
                const oldResponse = (viewMore ? this.state.response:[]);
                const response = oldResponse.concat(data.response);
                data.response.map(file => {
                    if(file.favourite){
                        favourites.push(file.id);
                    }
                    return null;
                });
                this.OneDrivelocalDataStore(response);
                this.setState({
                    totalItemCount: data.totalItemCount,
                    response: response,
                    loading: false,
                    loadingMore: false,
                    totalPages: data.totalPages,
                    favourites: favourites,
                    sharedWithUsers: ( data.sharedWith ? data.sharedWith : [])
                });
                this.loadingFolder = false;
            }).catch(err => {
                console.log(err);
            });
            localStorage.removeItem('parent_id_drive');
            localStorage.removeItem('main_Id');
            localStorage.removeItem('folder_Id');
            this.setState({activeTab:0})
        }
       
        let newState = {
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false,
            newFolderModal: false,
            newFolderModalOneDrive : false,
            creatingFolder: false,
            copyFilesModal: false,
            CopyMyDocModal: false,
            copyingFilesModal: false,
            copyingMyDocsFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            files: [],
            errorMessage: null,
            checkedAll: false,
            filesCopiedSize: 0,
            canceledCopyingMyDocsFilesModal: false,
        };
        if(!viewMore){
            newState['totalPages'] = 0;
        }
        this.setState(newState);
        this.loadingFolder = true;
    }

    checkFileSizeOneDrive(orignalSize=0,auto=0){
        const { user } = this.store.getState();
        const { oldCheckedFile } =this.state;
        const files = this.getCheckedFiles()
        const uniqueValues = files.filter(value => !oldCheckedFile.some(obj => obj=== value.id ));
        const totalSize = uniqueValues.reduce((accumulator, obj) => accumulator + obj.orignalSize, 0);
        const overAllSize = totalSize + orignalSize;
        const leftMemorySize = (user.quota - user.usage);
        if (leftMemorySize < overAllSize ) {
            if (auto) {
                return false;   
            }
            alert('Not enough storage space in My Docs');
            return false
        }
        return true
    }
    convertDateFormet(inputDateTime){
        const dateObj = new Date(inputDateTime);
        const formattedDateTime = dateObj.toISOString().replace("T", " ").replace("Z", "");
        return formattedDateTime.split(".")[0]
    }
    backSingleFolder(){
        let MainIdM = null
            // if(IsoneDriveParent){
                MainIdM = localStorage.getItem("parent_id_drive");
            // }else{
                // MainIdM =  localStorage.getItem("main_Id");
            // }
           const folderId = localStorage.getItem("folder_Id");
            this.loadOneDriveNested(MainIdM,folderId)
    }
    async OneDrivelocalDataStore(OneDrivelocalData){
        const { user } = this.store.getState();
        if(user.extensions.one_drive.status === 0){
            return true
        }
        const is_connected = localStorage.getItem('one_drive_ac_connect');
        if (!is_connected) {
            const requestData = {
                "userId":user?.id
            }
            const source = axios.CancelToken.source();
            ApiOneDrive.updateSyncStatus(requestData, source).then(data => {
            }).catch(err => {
                console.log(err);
            });
            return true;
        }
        let newStoreData=[]
        let destinationPathOneDrive = null;
        if(OneDrivelocalData.length>0){
            let requestData =[];
            let requestDataFolder = [];
            for (let i = 0; i < OneDrivelocalData.length; i++) {
                if(OneDrivelocalData[i].driveName === 'onedrive' && OneDrivelocalData[i].flagStatus && OneDrivelocalData[i].type === "file" && OneDrivelocalData[i].parent_id === 0 ){
                    newStoreData.push(OneDrivelocalData[i]);
                    await ApiOneDrive.getOneDriveFilesStatus(OneDrivelocalData[i].itemeId,OneDrivelocalData[i].driveId).then(data => {
                        if(data === 'data not founded'){
                            const source = axios.CancelToken.source();
                            const requestData = {
                                itemId: OneDrivelocalData[i].itemeId,
                            }
                            Api.deleteSingleFile(requestData, source).then(data => {}).catch(err => {console.log(err);});
                        }else{
                            const getDate= this.convertDateFormet(data.lastModifiedDateTime)                        
                            if(new Date(getDate) > new Date(OneDrivelocalData[i].oneDriveUpated)){
                            const file =  this.formatRquestdata(data);
                                requestData.push({
                                    'url':file.downloadLink,
                                    'file_name':file.name,
                                    'file_size':file.orignalSize,
                                    'file_mime':file.mime_major,
                                    'created_at' : file.date,
                                    'updated_at' : file.modified_date,
                                    'flag' : 'SyncToMyDocs',
                                    'driveId' : file.parent_id,
                                    'itemId' : file.id,
                                    'destinationPath':OneDrivelocalData[i]?.destinationPath,
                                    'flagStatus':true,
                                    "extension":OneDrivelocalData[i].extension
                                })
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }else if(OneDrivelocalData[i].driveName === 'onedrive' && OneDrivelocalData[i].flagStatus && OneDrivelocalData[i].type === "folder" && OneDrivelocalData[i].parent_id !== 0){
                    await ApiOneDrive.checkFolderOneDriveModifi(OneDrivelocalData[i].itemeId).then(data => {
                        const getDate= this.convertDateFormet(data.lastModifiedDateTime)     

                        let yesNo = true;
                        if (user.extensions.one_drive.path != null) {
                             yesNo = OneDrivelocalData[i].path.includes(user.extensions.one_drive.path);
                        }
                        if(new Date(getDate) > new Date(OneDrivelocalData[i].oneDriveUpated) && yesNo){
                            destinationPathOneDrive = OneDrivelocalData[i]?.destinationPath;
                            requestDataFolder.push(data);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }
            if(requestData.length>0){
                const checkTrue = this.checkFileSizeOneDrive(0,1)
                if (checkTrue) {
                    this.moveFileOneDrive('SyncToMyDocs',requestData, 1);
                }else{
                    console.warn('Not enough storage space in My Docs');
                }
            }
            
            if(requestDataFolder.length>0){
                const checkTrue = this.checkFileSizeOneDrive(0,1)
            
                if (checkTrue) {
                    const source = axios.CancelToken.source();
                    for (let index = 0; index < requestDataFolder.length; index++) {
                        
                        const requestData={
                            "folderName":requestDataFolder[index].name,
                            "folderUrl":'',
                            "folderParentId":4,
                            "flagStatus":true,
                            "itemId":requestDataFolder[index].id,
                            "driveId":requestDataFolder[index]?.parentReference?.driveId,
                            "created_at":requestDataFolder[index]?.createdDateTime,
                            "updated_at":requestDataFolder[index]?.lastModifiedDateTime,
                            "folderParentIdOneDrive":requestDataFolder[index].id,
                            "parent_path":requestDataFolder[index].name,
                            "destinationPath":destinationPathOneDrive
                        }
                        this.setState({
                            loading: true,
                            folderParentIdOneDrive:requestDataFolder[index].id,
                            folderParentPathOneDrive:requestDataFolder[index].name
                        });
                        Api.insertFolerEntryToLocal(requestData, source).then(data1 => {
                            this.getAllDataFolderOneDrive(requestDataFolder[index].parentReference.driveId, requestDataFolder[index].id, requestDataFolder[index].name, data1.parent_path, destinationPathOneDrive);
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                   
                }else{
                    console.warn('Not enough space in memory.');
                }   
            }
        }
    }
    getNewFolderOrFile(type,destinationPath = null){
        const { oldCheckedFilePath } = this.state;
        const files = this.getCheckedFiles()
        const uniqueValues = files.filter(value => !oldCheckedFilePath.some(obj => obj=== destinationPath+value.name ));
        const uniqueFilesFolder = uniqueValues.filter(value => value.type === type)
       
      return uniqueFilesFolder;
    }
    getActualPath(pathNew,nameNew){
        pathNew = decodeURIComponent(pathNew);
        nameNew = decodeURIComponent(nameNew);
        const position = pathNew.indexOf(nameNew);
        let orPath;
        if (position !== -1) {
            orPath = "/"+pathNew.substring(position);
        } else {
            orPath = pathNew;
        }
       
        return orPath;
    }
    onNewFolderModalOneDrive(status = false,fPath){
        this.setState({
            newFolderModalOneDrive: status,
            newFolderModalOneDrivePath: fPath,
        });
    }
    async getAllDataFolderOneDrive(pId, folderId, pName, pPath, destinationPath = null, mainFolder = null, closeCopyModal = true, sourceParam = null){
        const { path, folderParentIdOneDrive, /*folderParentPathOneDrive*/ } = this.state;
        if(closeCopyModal){
            this.setState({
                loading: true,
            });
        }
        const getFolderPathOneDrive = (fullPath) => {
            const path = fullPath;
            const dataAfterRoot = path.split("root:")[1];
            return dataAfterRoot;
        };
        const getFileExtension = (filename) => {
            const parts = filename.split('.');
            return parts[parts.length - 1];
        };

        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }

        const response = await apiUrl.getDataAllFolderOneDrive(pId, folderId).then(data => {
            return data;
        }).catch(err => {
            console.log(err);
        });
        const { processedFileIds } = this.state;
        const folderData = response.value;

        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
            this.setState({
                cancelToken: source,
            })
        }
        const processedFileIds1 = [...processedFileIds];
        if(folderData.length>0){
            const newDataPush1 = [];
            let filesSize = 0;
            for (let index = 0; index < folderData.length; index++) {
                if (!folderData[index]?.folder) {
                    const fileId = folderData[index]?.id;
                    if (processedFileIds.includes(fileId)) {
                        continue;
                    }
                    filesSize = filesSize + folderData[index]?.size;

                    if (destinationPath) {
                        newDataPush1.push({
                            'url': folderData[index]['@microsoft.graph.downloadUrl'],
                            'file_name':folderData[index]?.name,
                            'file_size':folderData[index]?.size,
                            'file_mime':folderData[index]?.file?.mimeType,
                            'parentId':pId,
                            'filePrentPath':decodeURIComponent(this.getActualPath(getFolderPathOneDrive(folderData[index]?.parentReference?.path),pPath)),
                            'created_at' : folderData[index]?.createdDateTime,
                            'updated_at' : folderData[index]?.lastModifiedDateTime,
                            'flag' : "flag",
                            'driveId' : folderData[index]?.parentReference?.driveId ,
                            'itemId' : folderData[index]?.id,
                            'flagStatus':true,
                            'extension':getFileExtension(folderData[index]?.name),
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName,
                            "destinationPath":destinationPath
                        });
                    }else{
                        newDataPush1.push({
                            'url': folderData[index]['@microsoft.graph.downloadUrl'],
                            'file_name':folderData[index]?.name,
                            'file_size':folderData[index]?.size,
                            'file_mime':folderData[index]?.file?.mimeType,
                            'parentId':pId,
                            'filePrentPath':decodeURIComponent(this.getActualPath(getFolderPathOneDrive(folderData[index]?.parentReference?.path),pPath)),
                            'created_at' : folderData[index]?.createdDateTime,
                            'updated_at' : folderData[index]?.lastModifiedDateTime,
                            'flag' : "flag",
                            'driveId' : folderData[index]?.parentReference?.driveId ,
                            'itemId' : folderData[index]?.id,
                            'flagStatus':true,
                            'extension':getFileExtension(folderData[index]?.name),
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName
                        });
                    }
                    processedFileIds1.push(fileId); 
                    this.setState({processedFileIds:processedFileIds1});
                }
            }  
            const childFolders = folderData.filter((item) => item.folder);
            const childDataPromises = childFolders.map((folder) => {
                return folder;
            });
            if (newDataPush1.length > 0 ) {
                apiUrl.moveFileOneDriveToLocal(newDataPush1, source).then(data => {
                    if(mainFolder !== null){
                        mainFolder.countCopiedFiles = mainFolder.countCopiedFiles + newDataPush1.length;
                    }
                    if(mainFolder.countFiles === mainFolder.countCopiedFiles){
                        mainFolder.copied = true;
                    }

                    this.setState({
                        loading: false,
                        filesCopiedSize: this.state.filesCopiedSize + filesSize
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
            if (childDataPromises.length > 0) {
                for (let j = 0; j < childDataPromises.length; j++) {
                    let requestData;
                    if (destinationPath) {
                        requestData ={
                            "folderName":childDataPromises[j].name,
                            "folderUrl":decodeURIComponent(this.getActualPath(getFolderPathOneDrive(childDataPromises[j]?.parentReference?.path),pPath)),
                            "folderParentId":pId,
                            "flagStatus":false,
                            "itemId":childDataPromises[j].id,
                            "driveId":childDataPromises[j]?.parentReference?.driveId,
                            "created_at":childDataPromises[j].createdDateTime,
                            "updated_at":childDataPromises[j].lastModifiedDateTime,
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName,
                            "destinationPath":destinationPath
                          }
                    }else{
                        requestData ={
                            "folderName":childDataPromises[j].name,
                            "folderUrl":decodeURIComponent(this.getActualPath(getFolderPathOneDrive(childDataPromises[j]?.parentReference?.path),pPath)),
                            "folderParentId":pId,
                            "flagStatus":false,
                            "itemId":childDataPromises[j].id,
                            "driveId":childDataPromises[j]?.parentReference?.driveId,
                            "created_at":childDataPromises[j].createdDateTime,
                            "updated_at":childDataPromises[j].lastModifiedDateTime,
                            "folderParentIdOneDrive":folderParentIdOneDrive,
                            "parent_path":pName
                        }
                    }
                    Api.insertFolerEntryToLocal(requestData, source).then(data => {
                        if(mainFolder !== null){
                            mainFolder.countCopiedFiles = mainFolder.countCopiedFiles + 1;
                        }
                        if(mainFolder.countFiles === mainFolder.countCopiedFiles){
                            mainFolder.copied = true;
                        }
                        if (destinationPath) {
                            this.getAllDataFolderOneDrive(childDataPromises[j].parentReference.driveId, childDataPromises[j].id, childDataPromises[j].name, data.parent_path, destinationPath, mainFolder, closeCopyModal);
                        }else{
                            this.getAllDataFolderOneDrive(childDataPromises[j].parentReference.driveId, childDataPromises[j].id, childDataPromises[j].name, data.parent_path, null, mainFolder, closeCopyModal);
                        }

                        this.setState({
                            loading: false,
                        });
                    }).catch(err => {
                            console.log(err);
                       });
                    }
            }
        }
    }
    inserFolderOneDrive(flag = null, data, destinationPath = null, closeCopyModal = true, sourceParam = null){
        let flageS = false;
        if (flag === "SyncToMyDocs") {
            flageS = true;
        }

        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
        }

        for (let index = 0; index < data.length; index++) {
            let requestData;
            if (destinationPath) {
                requestData={
                    "folderName":data[index].name,
                    "folderUrl":'',
                    "folderParentId":4,
                    "flagStatus":flageS,
                    "itemId":data[index].id,
                    "driveId":data[index].parent_id,
                    "destinationPath":decodeURIComponent(destinationPath),
                    "created_at":data[index].created_at,
                    "updated_at":data[index].updated_at
                }
            }else{
                requestData={
                    "folderName":data[index].name,
                    "folderUrl":'',
                    "folderParentId":4,
                    "flagStatus":flageS,
                    "itemId":data[index].id,
                    "driveId":data[index].parent_id,
                    "created_at":data[index].created_at,
                    "updated_at":data[index].updated_at
                }
            }
            let stateObj = {
                // loading: true,
                copyingMyDocsFilesModal: true,
                folderParentIdOneDrive:data[index].id,
                folderParentPathOneDrive:data[index].name
            };
            if(sourceParam === null){
                stateObj['cancelToken'] = source;
            }

            this.setState(stateObj);
            Api.insertFolerEntryToLocal(requestData, source).then(data1 => {
                let files = this.getCheckedFiles();
        
                let mainFolder = files.find(obj => obj.id === data[index].id);
                
                mainFolder.countCopiedFiles = 0;
                if(mainFolder.countFiles <= 0 || mainFolder.countFiles === mainFolder.countCopiedFiles){
                    mainFolder.copied = true;
                }
                
                if (destinationPath) {
                    this.getAllDataFolderOneDrive(data[index].parent_id, data[index].id, data[index].name, data1.parent_path, destinationPath, mainFolder, closeCopyModal, sourceParam);
                }else{
                    this.getAllDataFolderOneDrive(data[index].parent_id, data[index].id, data[index].name, data1.parent_path, null, mainFolder, closeCopyModal, sourceParam);
                }
                if(closeCopyModal){
                    this.setState({
                        copyMyDocModal:false,
                        copyingMyDocsFilesModal: false,
                    });
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }
    moveFileOneDrive(flag, filesOne=null, isSync = null, destinationPath = null, closeCopyModal = true, sourceParam = null){
        const { path } = this.state;

        this.setState({
            // loading: true,
            copyingMyDocsFilesModal: true,
        });
        let file ;
        if (isSync) {
            file =filesOne;
        }else if(filesOne){
            file =filesOne;
        }
        else{
            file = this.getCheckedFiles()
        }
        let requestData = [];
        if (file.length <= 0) {
            return false;
        }
        let source = sourceParam;
        if(sourceParam === null){
            source = axios.CancelToken.source();
            this.setState({
                cancelToken: source,
            });
        }

        let filesSize = 0;
        if(isSync){
            for (let index = 0; index < file.length; index++) {
                filesSize = filesSize + file[index].file_size;

                requestData.push({
                    'url':file[index].url,
                    'file_name':file[index].file_name,
                    'file_size':file[index].file_size,
                    'file_mime':file[index].file_mime,
                    'created_at' : file[index].created_at,
                    'updated_at' : file[index].updated_at,
                    'flag' : flag,
                    'driveId' : file[index].driveId ,
                    'destinationPath':destinationPath,
                    'itemId' : file[index].itemId,
                    'flagStatus':file[index].flagStatus,
                    'extension':file[index].extension
                })
            }
        }else{
            for (let index = 0; index < file.length; index++) {
                filesSize = filesSize + file[index].orignalSize;

                requestData.push({
                    'url':file[index].downloadLink,
                    'file_name':file[index].name,
                    'file_size':file[index].orignalSize,
                    'file_mime':file[index].mime_major,
                    'created_at' : file[index].date,
                    'updated_at' : file[index].modified_date,
                    'flag' : flag,
                    'destinationPath':destinationPath,
                    'driveId' : file[index].parent_id ,
                    'itemId' : file[index].id,
                    'flagStatus':true,
                    'extension':file[index].extension
                })
            }
        }

        let apiUrl = ApiOneDrive;
        if(path.includes("/Sharepoint")){
            apiUrl = ApiSharepoint;
        }
        apiUrl.moveFileOneDriveToLocal(requestData, source).then(data => {
            let files = this.getCheckedFiles();
            
            file.map((fileData) => {
                let matchingObj = files.find(obj => obj.id === fileData.id);
                matchingObj.copied = true;
                
                return null;
            });

            this.setState({
                loading: false,
                filesCopiedSize: this.state.filesCopiedSize + filesSize
            })

            if(closeCopyModal){
                this.setState({
                    copyingMyDocsFilesModal: false,
                },() => {
                    if (isSync === null) {
                        this.setState({
                            activeTab:0,
                            copyMyDocModal: false,
                        });
                        this.history.push("/user/home?dir=/My Docs");
                    }
                });
            }
        }).catch(err => {
            this.setState({
                loading: false,
            });
            console.log(err);
        });
    }
    moveData1(flag, destinationPath, source){ 
        const getAllNewFolder = this.getNewFolderOrFile('folder',destinationPath);
        if(getAllNewFolder.length>0){
            this.inserFolderOneDrive(flag, getAllNewFolder, destinationPath, false, source);
        }
        const getAllNewFile = this.getNewFolderOrFile('file',destinationPath);
        if(getAllNewFile.length>0){
            this.moveFileOneDrive(flag, getAllNewFile, null, destinationPath, false, source);
        }
    }
    onCancelCopyFiles(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }

        this.setState({
            cancelToken: null,
            showError: false,
            errorMessage: "",
            canceledCopyingMyDocsFilesModal: true
        });
    }
    myDocModal(flag){
        this.onCopyMyDocModal(true,flag);
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        if (backPath.includes('OneDrive')) {
            backPath = '/'+backPath;
        }
        return backPath;
    }
    checkUncheckOneDrive(file){
        const source = axios.CancelToken.source();
                const requestData = {
                    itemId: file.id,
                    flagstatus : true
                }
        Api.getSingleFile(requestData, source).then(data => {
                this.loadFiles()
            }).catch(err => {
                console.log(err);
            });
    }
    handleToggle(file, onContextMenu = false) {
        let {  path } = this.state;
        if(this.loadingFolder || file.id === 0){
            return;
        }
        // if(file.default){
        //     return;
        // }
        
        let newChecked = [];
        if(onContextMenu || this.state.detailsSection){
            newChecked = [file.id];
        }else{
            const { checked } = this.state;
            const currentIndex = checked.indexOf(file.id);
            newChecked = [...checked];
        
            if (currentIndex === -1) {
                // if (path.includes("/OneDrive")){
                //     if(this.checkFileSizeOneDrive(file?.orignalSize)){
                //         newChecked.push(file.id);
                //     }
                // }else{
                //     newChecked.push(file.id);
                // }
                newChecked.push(file.id);
            } else {
                newChecked.splice(currentIndex, 1);
                if (path.includes("/OneDrive") && file?.id){
                //   this.checkUncheckOneDrive(file);
                }
            }
        }

        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null),
            detailsSection: newChecked.length > 0 && this.state.detailsSection ? true : false
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    openFileModal(file){
        if(this.loadingFolder){
            return;
        }
        let newChecked = [file.id];
        const downloadFile = (newChecked.length === 1 && file.type === "file");
        this.setState({
            checked: newChecked,
            downloadFile: downloadFile,
            file: (downloadFile ? this.getCheckedFile(newChecked) : null)
        },() => {
            if(helper.hasThumbnail(file)){
                this.onImagePreviewModal(true);
            }else if(helper.isOfficeDocument(file)){
                this.onOfficeFileModal('view');
            }else if(helper.isPdfDocument(file)){
                this.onPdfFileModal(true);
            }else{
                this.onNoPreviewModal(true)
            }
        });
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button justIcon color="transparent" className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
                folderPath: "",
                recipients: "",
            };
            tableData.push(fileArray);
        }
        let {  path } = this.state;
        let newChecked = [];
      
        newChecked = [...checked];
        response.map(file => {
             if((file.type === "folder" && file.path === "/My Docs") || (file.path === "/OneDrive" && file.type === "folder")){
                return null;
            }
          
            if ( path.includes("/OneDrive")){
                if(file.flagStatus){
                    const currentIndex = newChecked.indexOf(file.id);
                    if (currentIndex === -1) {
                        newChecked.push(file.id);
                        this.setState({
                            checked: newChecked,
                        });
                    }
                }
           
             }
 
            let fileArray = {
                id: file.id,
                default: file.name,
                type: file.type,
                downloadLink: file?.downloadLink ? file?.downloadLink :  file['@microsoft.graph.downloadUrl'],
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id) }
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                         checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalName: file.name,
                name: (
                    <ContextMenuTrigger id="file_dropdown_menu" collect={() => {return file}}>
                        {this.getFileName(file)}
                    </ContextMenuTrigger>
                ),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file?.updated_at ?  this.convertDateFormet(file?.updated_at) : file?.lastModifiedDateTime,
                owner: file?.owner,
                lastModifiedBy: file?.last_modified_by ?file?.last_modified_by : file?.lastModifiedDateTime,
                recipients: (
                    <div className={classes.recipients}>{this.getRecipients(file)}</div>
                ),
                folderPath: file?.folder_path,
                relatedLinks: this.getRelatedLink(file)
            };
            tableData.push(fileArray);
            return null;
            
        });
            
        return tableData;
    }
    getFileName(file){
        const modificationAllowed = this.isModificationAllowed();
        const { classes } = this.props;
        const { favourites } = this.state;
        let shareFileId = '';
        if(file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0){
            shareFileId = file.id;
        }
        return (
            <div title={file.name} onDoubleClick={(e) => this.openFileModal(file)}>
                <Button justIcon color="transparent" className={classes.fileIcon} onClick={() => this.favourite(file)}>
                    {
                        favourites.includes(file.id) ?
                            <MaterialIcon path={mdiStar} className="MuiSvgIcon-root star_filled" />
                        :
                            <MaterialIcon path={mdiStarOutline} className="MuiSvgIcon-root star_outlined" />
                    }
                </Button>
                {
                    file.type === "folder" ?
                        <Link href={"/user/home"} onClick={(e) => this.loadFolder(e, file, shareFileId)}>
                            <Button justIcon color="transparent" className={classes.fileIcon}>
                                {
                                    file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                        <img src={sharedFolderIcon} alt={"folder"} />
                                    :
                                        <img src={foldericon} alt={"folder"} />
                                }
                            </Button>
                            { file.name }
                        </Link>
                    :
                    <>    
                        { 
                            helper.getFileIcon(file, classes, modificationAllowed)
                        }
                        { file.name }
                    </>
                }
            </div>
        )
    }
    getFileNameGrid(file){
        const modificationAllowed = this.isModificationAllowed();
        
        const { classes } = this.props;
        const { checked } = this.state;

        let shareFileId = '';
        if(file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0){
            shareFileId = file.id;
        }
        return (
            <div title={file.name} className={checked.includes(file.id) ? 'checked' : ''}  onClick={(e) => this.handleToggle(file)} onDoubleClick={(e) => this.openFileModal(file)}>
                {
                    file.type === "folder" ?
                        <>
                            <div className='thumbnail-icon'>
                                <Button justIcon color="transparent" className={classes.fileIcon}>
                                    {
                                        file.hasOwnProperty("sharedWith") && file.sharedWith.length > 0 ?
                                            <img src={sharedFolderIcon} alt={"folder"} />
                                        :
                                            <img src={foldericon} alt={"folder"} />
                                    }
                                </Button>
                            </div>
                            <Link href={"/user/home"} onClick={(e) => this.loadFolder(e, file, shareFileId)}>
                                <div className='filename'>{file.name}</div>
                            </Link>
                        </>
                    :
                        <>
                            <div className='thumbnail-icon'>
                                { 
                                    helper.getFileIcon(file, classes, modificationAllowed, true)
                                }
                            </div>
                            <div className='filename'>{file.name}</div>
                        </>
                }
            </div>
        )
    }
    openRecipientLinks(email){
        let url = "/user/links?page=1&recipientEmail="+email;
        this.history.push(url);
    }
    getRecipients(file){
        if(file.transfer_type === "share"){
            return <div>Anyone (links only)</div>;
        }
        // if(file?.recipients.length <= 1){
        //     return <div>{file.recipients.join(",")}</div>;
        // }
        const firstTwoRecipients = file.recipients.slice(0, 2);
        let recipientsList = [];
        file.recipients.map((email, key) => {
            recipientsList.push(
                <li key={key} onClick={() => this.openRecipientLinks(email)}>{email}</li>
            );
            return null;
        });
        return (
            <>
                <div>
                    <span className='recipients-list'>{firstTwoRecipients.join(" , ")}</span>
                    {
                        file.recipients.length > 1 ?
                            <span className='recipients-count'>({file.recipients.length})</span>
                        :
                            <></>
                    }
                </div>
                <ul>
                    {recipientsList}
                </ul>
            </>
        );
    }
    getRelatedLink(file){
        if(file.relatedLinks.length <= 0){
            return null;
        }
        const url = "/user/links?page=1&links="+file.relatedLinks.join(",");
        return (
            <RouterLink to={url}>
                <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
            </RouterLink>
        );
    }
    onRelatedLinks(){
        const { checked, response, path } = this.state;
        if(checked.length <= 0){
            return;
        }

        let url = "/user/links?page=1&files="+checked.join(",");

        if(path.indexOf("/Email/Web") !== -1 || path.indexOf("/Email/Outlook") !== -1){
            let relatedLinks = [];
            response.map(file => {
                if(checked.includes(file.id)){
                    if(file.relatedLinks.length > 0){
                        relatedLinks.push(file.relatedLinks);
                    }
                }
                return null;
            })
            relatedLinks = [].concat(...relatedLinks);

            if(relatedLinks.length > 0){
                url = "/user/links?page=1&links="+relatedLinks.join(",");
            }
        }

        this.history.push(url);
    }
    loadFolder(e, parent, shareFileId = null){
        localStorage.setItem("main_Id", parent.id);
        localStorage.setItem("folder_Id", parent.parent_id);
        localStorage.setItem("parent_id_drive",parent.parent_id_drive)
        e.preventDefault();
        this.setState({
            path: parent.path,
            shareFileId: shareFileId
        }, () => {
            this.loadFiles(false);
        });
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
            }
        }else{
            return {}
        }
    }
    getTrGroupProps(state, rowInfo){
        const { backPath } = this.state;
        if(backPath.length <= 0){
            return {};
        }
        if (rowInfo && rowInfo.row) {
            return {
                className: (rowInfo.original.id === 0 ? 'frozen_row' : '')
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            deleteAll: false,
            checkedAll: false,
        });
    }
    onModalSuccess(){
        const { shareFileId } = this.state;
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
        }
        Api.deleteFiles(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                deleteAll: false,
                checkedAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    onUploadModal(uploadFileModal = true){
        if(!uploadFileModal){
            if(this.state.resetComponent){
                this.store.dispatch({ type: 'UPDATE_STATE', state: {
                    customState: null
                }});
            }
            this.loadFiles(false);
            return;
        }
        this.setState({
            uploadFileModal: uploadFileModal,
        });
    }
    onUploadModalSuccess(){
        this.loadFiles(false);
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        }, () => {
            this.handleToggle(file);
        });
    }
    onLinkName(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }

        this.setState({
            linkName: status,
            shareFileModal: status,
        });
    }
    onShareModal(status = false, linkTitle = null, linkPassword = null){
        const { checked, path } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
            path: path
        };
        if(typeof(path) === "string" && path.indexOf("/My Docs") !== -1){
            requestData['uploadType'] = "my_docs";
        }
        if(linkTitle !== null){
            requestData['linkTitle'] = linkTitle;
        }
        if(linkPassword !== null){
            requestData['linkPassword'] = linkPassword;
        }
        
        Api.shareFiles(requestData, source).then(data => {
            if(data.status === 'uploaded'){
                this.setState({
                    sharingFiles: false,
                    newLinkUploaded: true
                })

                return;
            }
            
            let downloadLink = "/downloads/"+data.hash;
            this.setState({sharingFiles: false, downloadLink: downloadLink, linkHash: data.hash});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, errorMessage: err.message, sharingFiles: false});
            }
        });
        this.setState({
            shareFileModal: status,
            sharingFiles: status,
            cancelToken: source,
            linkName: false
        });
    }
    onAdvShareModal(status = false){
        const { checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        
        this.setState({
            advShareModal: status,
        });
        if(!status){
            this.loadFiles();
        }
    }
    onShareModalSuccess(){
        this.setState({
            shareFileModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false
        });
        this.loadFiles();
    }
    formatRquestdata(data){
        const getFolderPath = (fullPath) => {
            const path = fullPath;
            const dataAfterRoot = path.split("root:")[1];
            const returnFullPath = "/OneDrive"+dataAfterRoot;
            return returnFullPath;
          };
          const getFolderPathOneDrive = (fullPath) => {
            const path = fullPath;
            const dataAfterRoot = path.split("root:")[1];
            return dataAfterRoot;
          };
        
        const getFileExtension = (filename) => {
            const parts = filename.split('.');
            return parts[parts.length - 1];
          };
          const convertBitsToKbMb = (sizeInBits) => {
            let customeSize;
            const sizeInKb = (sizeInBits / 1024).toFixed(2);
            customeSize = sizeInKb +' kb';
            if (sizeInKb >= 1024) {
              const sizeInMb = (sizeInKb / 1024).toFixed(2); 
              customeSize = sizeInMb +' mb';
            }
            return customeSize;
          };
          const file = data;
          const downloadUrl = file['@microsoft.graph.downloadUrl'];
        
              const newData = {
                "id":data.id,
                "parent_id":data?.parentReference.driveId,
                "parent_id_drive":data?.parentReference.id,
                "type":data?.folder ? 'folder' : 'file',
                "name":data.name,
                "orignalSize":data?.size,
                "size":convertBitsToKbMb(data?.size),
                "date":data?.createdDateTime,
                "modified_date":data?.lastModifiedDateTime,
                "favourite":false,
                "default":0,
                "downloadLink":downloadUrl,
                "recipients":[
                ],
                "transfer_type":"one_drive",
                "pathOneDrive":decodeURIComponent(getFolderPathOneDrive(data?.parentReference?.path)),
                "path":decodeURIComponent(getFolderPath(data?.parentReference?.path)+"/"+data?.name),
                "folder_path":"/OneDrive",
                
                "mime_major":data?.file?.mimeType,
                "extension":getFileExtension(data.name),
                "created_at":data?.createdDateTime,
                "updated_at":data?.lastModifiedDateTime,
                "folder_id":data?.parentReference?.driveId, 
              }
              return newData;
    }
    onDownloadModal(status = false){
        const { checked, path, downloadFile, file } = this.state;
        if(checked.length <= 0){
            return;
        }
        if(downloadFile && checked.length === 1 && file){
            window.location.href = file.downloadLink;
            this.trackFileDownload();
            return;
        }

        let source = null;
        if(status){
            source = axios.CancelToken.source();
            const requestData = {
                files: checked,
                path: path
            };
            Api.downloadFiles(requestData, source).then(data => {
                this.setState({downloadingFiles: false, downloadUrl: data.downloadUrl});
            }).catch(err => {
                if(err && err.message !== "Request Cancelled"){
                    this.setState({cancelToken: null, errorMessage: err.message, downloadingFiles: false});
                }
            });
        }

        this.setState({
            downloadModal: status,
            downloadingFiles: status,
            cancelToken: source
        });
    }
    trackFileDownload(){
        const { shareFileId, checked } = this.state;
        if(checked.length <= 0){
            return;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: checked,
            file_id: shareFileId,
        };
        Api.trackFileDownload(requestData, source).then(data => {
            //Silent
        }).catch(err => {
            //Silent
        });
    }
    onDownloadModalSuccess(){
        this.setState({
            downloadModal: false,
            errorMessage: null
        });
        const { downloadUrl } = this.state;
        if(downloadUrl.length > 0){
            window.location.href = downloadUrl;
            this.trackFileDownload();
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [];
        if(path === "/My Docs" || path === "/" || path === "/OneDrive" || path === "OneDrive" || path === "/Sharepoint" || path === "Sharepoint"){
            return breadcrumbs;
        }
        if (path.indexOf("/My Docs") !== -1) {
            // let breadcrumbs = [{
            //     value: "/",
            //     label: "Home"
            // }];
        }
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    getBreadCrumbsOneDrive(){
        let { path } = this.state;
        let breadcrumbs = [];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            if ("MyDocs" === path) {
                return null;
            }
            if ("/OneDrive" === path) {
                newPath += "/"+path;
                breadcrumbs.push({
                    value: "/OneDrive",
                    label: path
                });
            }else if("/Sharepoint" === path) {
                newPath += "/"+path;
                breadcrumbs.push({
                    value: "/Sharepoint",
                    label: path
                });
            }else{
                newPath += "/"+path;
                breadcrumbs.push({
                    value: newPath,
                    label: path
                });
            }
            return null;
        });

        return breadcrumbs;
    }
    onNewFolderModal(status = false){
        this.setState({
            newFolderModal: status,
        });
    }
    onNewFolderModalSuccess(folderName,isOneDrive = false){
        const { shareFileId,newFolderModalOneDrivePath } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFolder: true,
            cancelToken: source,
        });
        let NewP = isOneDrive ? newFolderModalOneDrivePath:this.state.path;
        if (isOneDrive && NewP === "/"){
            NewP = "/My Docs"
        }
        const requestData = {
            folderName: folderName,
            path: NewP
        }
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.addNewFolder(requestData, source).then(data => {
            if(isOneDrive){
                this.setState({
                    loading: false,
                    newFolderModalOneDrive:false
                });
                this.onCopyMyDocModal(true,"CopyToMyDocs");
            }else{
                this.loadFiles();
            }
        }).catch(err => {
            console.log(err);
        });
    }
    getColumns(){
        const { classes } = this.props;
        const { path, shareFileId, searchFormData } = this.state;
        
        let header = "";
        if(path.indexOf("/My Docs") !== -1){
            header = (
                <Checkbox
                    tabIndex={-1}
                    checked={this.state.checkedAll}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    onChange={(e) => this.onToggleAllFiles(e)}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                />
            )
        }

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            }
        ];
        if(path !== "/" && path !== "/Email" && path !== "/Email/Outlook" && path !== "/My Docs" && !path.includes("/My Docs") && path !== "/OneDrive" && !path.includes("/OneDrive")  && path !== "/Sharepoint" && !path.includes("/Sharepoint")){
            columns.push({
                Header: "Recipient(s)",
                accessor: "recipients",
                headerClassName: "td_recipients",
                className: "hd_recipients td_recipients",
            });
        }
        columns.push({
            Header: "Creator",
            accessor: "owner",
            headerClassName: "hd_owner",
            className: "hd_owner td_owner",
        });
        if(shareFileId !== ''){
            columns.push({
                Header: "Last Modified By",
                accessor: "lastModifiedBy",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            });
        }
        columns.push({
            Header: "Uploaded",
            accessor: "date",
            headerClassName: "hd_creation_date",
            className: "hd_creation_date td_creation_date",
            sortMethod: (a, b) => {
                var a1 = new Date(a).getTime();
                var b1 = new Date(b).getTime();
              if(a1<b1)
                return 1;
              else if(a1>b1)
                return -1;
              else
                return 0;
            }
        });
        if(searchFormData.search || searchFormData.startDate || searchFormData.endDate){
            columns.push({
                Header: "Folder Path",
                accessor: "folderPath",
                headerClassName: "hd_path",
                className: "hd_path td_path",
            });
        }
        if((path !== "/Email" && path !== "/OneDrive" && !path.includes("/OneDrive")) && (path !== "/Sharepoint" && !path.includes("/Sharepoint"))){
            columns.push({
                Header: "Related Links",
                accessor: "relatedLinks",
                headerClassName: "hd_related_links",
                className: "hd_related_links td_creation_date",
            });
        }
        return columns;
    }
    onContextMenuItemClick(type, data){
        const checkedFile = this.getCheckedFile();
        switch(type){
            case 'share':
                this.onShareModal(true);
                break;
            case 'delete':
                this.deleteSelected();
                break;
            case 'download':
                this.onDownloadModal(true);
                break;
            case 'copy':
                this.onCopyModal(true);
                break;
            case 'move':
                this.onMoveModal(true);
                break;
            case 'rename':
                this.onRenameModal(true);
                break;
            case 'advShare':
            case 'permission':
                this.onAdvShareModal(true);
                break;
            case 'replace':
                this.onManageVersions(true);
                break;
            case 'view':
                this.onOfficeFileModal('view');
                break;
            case 'edit':
                if(checkedFile.extension === "wopitest" || checkedFile.extension === "wopitestx"){
                    this.onOfficeFileModal('edit');
                }else{
                    this.editOfficeDoc(checkedFile);
                }
                break;
            case 'imagePreview':
                this.onImagePreviewModal(true);
                break;
            case 'pdfPreview':
                this.onPdfFileModal(true);
                break;
            case 'detail':
                this.onDetailPreviewSection(true);
                break;
            case 'sign':
                this.onSignDocument(true);
                break;
            default:
                break;
        }
    }
    onDetailPreviewSection(status = false){
        let state = {};
        state['detailsSection'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state);
    }
    onPdfFileModal(status = false){
        let state = {};
        state['pdfFileModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onNoPreviewModal(status = false){
        let state = {};
        state['noPreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onImagePreviewModal(status = false){
        let state = {};
        state['imagePreviewModal'] = status;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    editOfficeDoc(file, newFile = false){
        let expiresAt = Cookie.read("expires_at");
        if(!expiresAt || expiresAt === "null"){
            expiresAt = 0;
        }
        let url = window.location.origin+"/document/edit/"+file.id;
        if(newFile){
            url = window.location.origin+"/document/editnew/"+file.id;
        }
        // this.editDocTokenRef.current.value = Cookie.read("oauth_token")+":"+Cookie.read("oauth_secret");
        this.editDocRef.current.setAttribute("action", url);
        this.editDocRef.current.submit();
    }
    onOfficeFileModal(action, status = true){
        let state = {};
        state['officeFileModal'] = status;
        state['officeFileAction'] = action;
        if(status === false){
            state['checked'] = [];
            state['downloadFile'] = false;
            state['file'] = null;
        }
        this.setState(state)
    }
    onManageVersions(versionsModal = false){
        this.setState({versionsModal: versionsModal}, () => {
            if(!versionsModal){
                this.loadFiles();
            }
        });
    }
    onContextMenuShow(e){
        const { checked } = this.state;
        if(checked.length > 1){
            return;
        }
        const file = e.detail.data;
        this.setState({
            sharedWith: (file ? file.sharedWith : null),
            currentFile: (file ? file : null),
        }, () => {
            this.handleToggle(file, true);
        });
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    onCopyMyDocModal(modal = true, flag, openMyDocs = false, loadFiles = false){
        let newFolderModalOneDrivePath = null;
        if(modal === true){
            newFolderModalOneDrivePath = this.state.newFolderModalOneDrivePath;
        }
        this.setState({
            copyMyDocModal: modal,
            actionFlag : flag,
            errorMessage: null,
            filesCopiedSize: 0,
            copyingMyDocsFilesModal: false,
            canceledCopyingMyDocsFilesModal: false,
            newFolderModalOneDrivePath: newFolderModalOneDrivePath
        },() => {
            if(openMyDocs){
                this.setState({
                    activeTab:0,
                });
                this.history.push("/user/home?dir=/My Docs");
            }else if(modal === false && loadFiles === true){
                this.loadFiles();
            }
        });        
    }
    onCopyDocModalSuccess(newPath){
        if(newPath === "/"){
            newPath = "/My Docs"
        }
        const source = axios.CancelToken.source();
        this.setState({
            cancelToken: source
        })

        const { actionFlag } = this.state;
        this.moveData1(actionFlag, newPath, source);
    }
    onCopyModal(modal = true){
        this.setState({
            copyFilesModal: modal,
            errorMessage: null
        });
    }
    onCopyModalSuccess(newPath){
        const { shareFileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            copyingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.copyFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    copyingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onMoveModal(modal = true){
        this.setState({
            moveFilesModal: modal,
            errorMessage: null
        });
    }
    onMoveModalSuccess(newPath){
        const { shareFileId } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            movingFilesModal: true,
            cancelToken: source,
        });
        const { checked } = this.state;
        const requestData = {
            path: newPath,
            files: checked
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.moveFiles(requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err.error === true){
                this.setState({
                    errorMessage: err.message,
                    movingFilesModal: false,
                    cancelToken: null
                });
            }
            console.log(err);
        });
    }
    onRenameModal(modal = true){
        this.setState({
            renameFileModal: modal,
            errorMessage: null
        });
    }
    onRenameModalSuccess(newFileName){
        const { shareFileId } = this.state;
        const checkedFile = this.getCheckedFile();
        if(checkedFile === null){
            return;
        }
        const source = axios.CancelToken.source();
        this.setState({
            renamingFileModal: true,
            cancelToken: source,
        });
        const requestData = {
            name: newFileName,
        };
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
        }
        Api.renameFile(checkedFile.id, requestData, source).then(data => {
            this.loadFiles();
        }).catch(err => {
            if(err && err.duplicate === true){
                this.setState({cancelToken: null, errorMessage: err.message, renamingFileModal: false});
            }
            console.log(err);
        });
    }
    getCheckedFile(checked = null){
        const { response } = this.state;
        if(checked === null){
            checked = this.state.checked;
        }
        if(checked.length <= 0){
            return null;
        }
        let currentFile = null;
        response.map((file) => {
            if(currentFile !== null){
                return null;
            }
            if(checked.indexOf(file.id) !== -1){
                currentFile = file;
            }
            return null;
        });
        return currentFile;
    }
    onDrop(filesArray){
        this.setState({
            files: filesArray,
            uploadFileModal: true
        });
    }
    isModificationAllowed(){
        const { path } = this.state;
        
        if(path.indexOf("/My Docs") !== -1){
            return true;
        }
        if(path === "/" || path === "/Email" || path.indexOf("/Email/Web") === 0 || path.indexOf("/Email/Outlook") === 0 || path.indexOf("/My Docs") === 0 || path.indexOf("/Mobile") === 0){
            return false;
        }
        return true;
    }
    onNewDocMenuSuccess(extension){
        this.setState({
            docMenuEl: null,
            newFileModal: true,
            newFileExtension: extension
        });
    }
    onNewFileModal(status = false){
        this.setState({
            docMenuEl: null,
            newFileModal: status,
            newFileExtension: null
        });
    }
    onNewFileModalSuccess(fileName){
        const { shareFileId } = this.state;
        const { newFileExtension } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            creatingFile: true,
            cancelToken: source,
        });

        const requestData = {
            fileName: (fileName+"."+newFileExtension),
            path: this.state.path
        }
        if(shareFileId !== ''){
            requestData['sharedFileId'] = shareFileId;
            requestData['sharedSection'] = false;
        }
        Api.addNewFile(requestData, source).then(data => {
            this.editOfficeDoc(data, true);
            this.setState({
                docMenuEl: null,
                newFileModal: false,
                newFileExtension: null,
                creatingFile: false,
                newFileError: false,
                newFileErrorMessage: null
            }, () => {
                this.loadFiles();
            });
        }).catch(err => {
            console.log(err);
        });
    }
    renderNewDocMenu(){
        const { docMenuEl } = this.state;
        const { classes } = this.props;
        return (
            <Menu
                id="new-doc-menu"
                anchorEl={docMenuEl}
                keepMounted
                open={Boolean(docMenuEl)}
                onClose={() => this.onNewFileModal(false)}
            >
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("docx")}>
                    {helper.getFileIcon({extension: "docx"}, classes)}
                    Word Document
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("xlsx")}>
                    {helper.getFileIcon({extension: "xlsx"}, classes)}
                    Excel Workbook
                </MuiMenuItem>
                <MuiMenuItem onClick={() => this.onNewDocMenuSuccess("pptx")}>
                    {helper.getFileIcon({extension: "pptx"}, classes)}
                    Powerpoint Presentation
                </MuiMenuItem>
            </Menu>
        )
    }
    onNewOfficeDoc(event){
        this.setState({
            docMenuEl: event.currentTarget
        });
    }
    getGridTableData(){
        const { classes } = this.props;
        const { response } = this.state;

        if(response === null){
            return [];
        }
        let tableData = [];
        
        response.map((file, key) => {
            let fileData =  (
                <div className={classes.gridDataItem} key={key}>
                    <ContextMenuTrigger id="file_dropdown_menu" collect={() => {return file}}>
                        {this.getFileNameGrid(file)}
                    </ContextMenuTrigger>
                </div>
            );

            tableData.push(fileData);
            return null;
        });

        return tableData;
    }
    onChangeLayout(type){
        this.setState({
            layoutType: type
        })
    }
    getHeading(){
        const { path } = this.state;
        const heading = "Email Transfers";
        if(path === "/"){
            return heading;
        }
        if(path.indexOf("/My Docs") !== -1){
            return "My Docs";
        }
        if(path.indexOf("/OneDrive") !== -1){
            return "One Drive";
        }
        if(path.indexOf("/Email/Web") !== -1){
            return "Web Transfers";
        }
        if(path.indexOf("/Email/Outlook") !== -1){
            return "Outlook Transfers";
        }
        return heading;
    }
    onTabChange(urlTab){
        if(urlTab === '0'){
            localStorage.removeItem('parent_id_drive');
            localStorage.removeItem('main_Id');
            localStorage.removeItem('folder_Id');
            this.setState({activeTab:0})
            this.history.push("/user/home?dir=/My Docs");
        }else if(urlTab === '1'){
            this.setState({activeTab:1})
            this.history.push("/user/home?dir=/OneDrive");
        }else if(urlTab === '2'){
            this.setState({activeTab:2})
            this.history.push("/user/home?dir=/Sharepoint");
        }
    }
    onNewEditAccountModal(status = false, submit = false) {
        this.setState({
            newEditAccountModal: status,
        });
    }
    onSharepointAccountModal(status = false, submit = false){
        this.setState({
            sharepointAccountModal: status,
        });
    }
    getFilesContent(files){
        const { classes } = this.props;
        const { loading, loadingMore, page, totalPages, path, searchFormData, response, totalItemCount, backPath, 
            layoutType, docMenuEl, checked, activeTab } = this.state; 
        const breadcrumbs = this.getBreadCrumbs();
        // const breadcrumbsonedrive = this.getBreadCrumbsOneDrive();
        const tableData = this.getTableData();
        const modificationAllowed = this.isModificationAllowed();
        const { user } = this.store.getState();
        let isSharePoint = false;
        let isOneDrive = false;
        let isMyDocsPage = false;
        if(path === "/Sharepoint" || path.includes("/Sharepoint")){
            isSharePoint = true;
        }else if(path === "/OneDrive" || path.includes("/OneDrive")){
            isOneDrive = true;
        }
        if(path.indexOf("/My Docs") !== -1 || isOneDrive || isSharePoint){
            isMyDocsPage = true;
        }

        let onedriveEmail = localStorage.getItem("one_drive_email");
        let sharepointEmail = localStorage.getItem("sharepoint_email");

        let content = (
            <div className={classes.content}>
                <div className={classes.controls}>
                    <GridItem className={classes.controlsTabs}>
                        <GridContainer className={classes.alignItems}>
                            <GridItem xs={12} sm={isMyDocsPage ? 12 : 6} md={8}>
                                {
                                    isMyDocsPage ?
                                        <>
                                            <div className='sd-custom-tabs'>
                                                <div className={'sd-custom-tab '+(activeTab === 0 ? 'active': '')} onClick={() => this.onTabChange("0")}>
                                                    <span>My Docs</span>
                                                </div>
                                                {
                                                    onedriveEmail !== null ?
                                                        <div className={'sd-custom-tab '+(activeTab === 1 ? 'active': '')}>
                                                            <img src={user.theme === "standard" ? onedriveIcon : onedriveIconLight} alt="OneDrive" onClick={() => this.onTabChange("1")} />
                                                            <span onClick={() => this.onTabChange("1")}>One Drive</span>
                                                            <Button justIcon onClick={() => this.onNewEditAccountModal(true)} color="transparent" title="edit">
                                                                <EditIcon />
                                                            </Button>
                                                        </div>
                                                    :
                                                    null
                                                }
                                                {
                                                    sharepointEmail !== null ?
                                                        <div className={'sd-custom-tab '+(activeTab === 2 ? 'active': '')}>
                                                            <img src={user.theme === "standard" ? onedriveIcon : onedriveIconLight} alt="OneDrive" onClick={() => this.onTabChange("2")} />
                                                            <span onClick={() => this.onTabChange("2")}>Sharepoint</span>
                                                            <Button justIcon onClick={() => this.onSharepointAccountModal(true)} color="transparent" title="edit">
                                                                <EditIcon />
                                                            </Button>
                                                        </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </>
                                    :
                                        this.getHeading()
                                }
                            </GridItem>
                            <GridItem xs={12} sm={isMyDocsPage ? 12 : 6} md={4}>                                
                                <SearchInput searchFormData={searchFormData} onSuccess={(searchData) => this.onSearchInputSuccess(searchData)} clearSearch={() => this.clearSearch()} history={this.history} store={this.store} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                        {
                            isOneDrive || isSharePoint ? 
                                null 
                            :
                                modificationAllowed ?
                                    <>
                                        <Button color="transparent" onClick={() => this.onUploadModal(true)} title="Upload">
                                            <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                            Upload
                                        </Button>
                                        <span className={classes.separator}></span>
                                        <Button color="transparent" onClick={() => this.onNewFolderModal(true)} title="New Folder">
                                            <img src={user.theme === 'standard' ? newFolderIcon : newFolderIconLight} alt={"New Folder"} /> New Folder
                                        </Button>
                                        <span className={classes.separator}></span>
                                        {
                                            user.extensions.ms_office ?
                                                <>
                                                    <Button color="transparent" aria-controls="new-doc-menu" aria-haspopup="true" onClick={(event) => this.onNewOfficeDoc(event)} title="New Office Doc">
                                                        <img src={user.theme === 'standard' ? newOfficeDoc : newOfficeDocLight} alt={"New Office Doc"} /> New Office Doc
                                                    </Button>
                                                    <span className={classes.separator}></span>
                                                    {
                                                        Boolean(docMenuEl) && user.account_type === 5 ?
                                                            this.renderConfirmationModal()
                                                        :
                                                            this.renderNewDocMenu()
                                                    }
                                                </>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                    <></>
                        }
                        {
                            isOneDrive || isSharePoint || checked.length <= 0 ? 
                                null 
                            :
                                <>
                                    {
                                        Config.isLimitedAccess() === false ?
                                            <>
                                                {
                                                    path.trim() !== "/" && (path.indexOf("/Email") === -1 || path.substr(1).split("/").length >= 3) ?
                                                        <>
                                                            <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onLinkName(true)} title="New Link">
                                                                <img src={user.theme === 'standard' ? newlinkicon : newlinkiconLight} alt={"link"} /> New Link
                                                            </Button>
                                                            <span className={classes.separator}></span>
                                                        </>
                                                    :
                                                    null
                                                }
                                                {
                                                    path !== "/Email" ?
                                                        <>
                                                            <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onRelatedLinks()} title="Related Links">
                                                                <img src={user.theme === 'standard' ? relatedLinksIcon : relatedLinksIconLight} alt={"link"} /> Related Links
                                                            </Button>
                                                            <span className={classes.separator}></span>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                            </>
                                        :
                                            null
                                    }
                                    <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.deleteSelected()} title="Delete">
                                        <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                        Delete
                                    </Button>
                                    <span className={classes.separator}></span>
                                    <Button color="transparent" disabled={checked.length <= 0 ? true : false} onClick={() => this.onDownloadModal(true)} title="Download">
                                        <MaterialIcon path={mdiArrowCollapseDown} className="MuiSvgIcon-root" />
                                        Download
                                    </Button>
                                </>
                        }
                    </div>
                </div>
                {
                    isOneDrive || isSharePoint ? 
                        <div className={classes.driveActions}>
                            <h4> 
                                {
                                    isOneDrive ?
                                        <><span>OneDrive : </span>{localStorage.getItem("one_drive_email")}</>   
                                    :
                                        <><span>Sharepoint : </span>{localStorage.getItem("sharepoint_email")}</>
                                }
                            </h4>
                            <Button color="transparent"
                                disabled={checked.length === 0 ? true : false}
                                // onClick={(e) => {this.myDocModal('CopyToMyDocs');}}
                                onClick={(e) => {
                                    const enoughSpace = this.checkFileSizeOneDrive();
                                    if (!enoughSpace) {
                                        console.warn('Not enough space in memory.');
                                    } else {
                                        this.myDocModal('CopyToMyDocs');
                                    }
                                }}
                                title="Copy To My Docs"
                            >
                                <FileCopyIcon className={classes.icons} />
                                Copy To My Docs
                            </Button>
                        </div> 
                    :
                        <></>
                }
                <div className={classes.breadcrumbs}>
                    <div>
                        {
                            breadcrumbs.length > 0 ?
                                <>
                                    <img src={foldericon} alt={"folder"} />
                                    {
                                        breadcrumbs.map((breadCrumb, key) => {
                                            return (
                                                <div key={key}>
                                                    {
                                                        key !== 0 ?
                                                            <span><ChevronRight /></span>
                                                        :
                                                        <></>
                                                    }
                                                    <Link href="/user/home" className={(breadCrumb.value === '/' ? 'breadcrumb-home' : '')} onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, breadCrumb.value);}}>
                                                        {breadCrumb.label}
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            :
                                <></>
                        }
                    </div>
                    {
                        !loading && response !== null ?
                            <div className={classes.itemCount}>
                                {
                                    totalItemCount === 1 ?
                                        totalItemCount+" item"
                                    :
                                        totalItemCount+" items"
                                }
                            </div>
                        :
                        <></>
                    }
                </div>
                {
                    loading === false && modificationAllowed ?
                        <div className={classes.filesLayoutType}>
                            {
                                layoutType === 'grid' ? 
                                    <Button color="transparent" justIcon className={classes.fileIcon} onClick={() => this.onChangeLayout('list')}>
                                        <ListIcon />
                                    </Button>
                                :
                                    <Button color="transparent" justIcon className={classes.fileIcon} onClick={() => this.onChangeLayout('grid')}>
                                        <GridIcon />
                                    </Button>
                            }
                        </div>
                    :
                        <></>
                }
                <div className={classes.filesContainer}>
                    <div className={classes.files}>
                        {
                            loading ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            null
                        }
                        {
                            layoutType === 'grid' ?
                                <>
                                    {
                                        loading === false && modificationAllowed ? 
                                            <div className={classes.gridTableData}>
                                                { this.getGridTableData() }
                                            </div>
                                        :
                                            <></>
                                    }
                                </>
                            :
                                <ReactTable
                                    columns={this.getColumns()}
                                    data={tableData}
                                    pageSizeOptions={[500]}
                                    defaultPageSize={50000000000}
                                    showPaginationTop={false}
                                    minRows={0}
                                    showPaginationBottom={false}
                                    className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable+" "+(backPath.length > 0 ? classes.stickyRow : "")}
                                    getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                    getTrGroupProps={(state, rowInfo) => this.getTrGroupProps(state, rowInfo)}
                                    getNoDataProps={() => this.getNoDataProps()}
                                />
                              
                        }
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
        
        if(path.indexOf("/My Docs") === 0 || path.indexOf("My Docs") === 0){
            return (
                <DragNDrop onDrop={(files) => this.onDrop(files)}>
                    { content }
                </DragNDrop>
            );
        }else{
            return (
                content 
            );
        }
    }
    canEditDocument(){
        const modificationAllowed = this.isModificationAllowed();
        if(!modificationAllowed){
            return false;
        }
        const checkedFile = this.getCheckedFile();
        return helper.canEditDocument(checkedFile);
    }
    onUpgradeModalClose(){
        this.setState({
            uploadFileModal: false,
            newFolderModal: false,
            newFolderModalOneDrive : false,
            creatingFolder: false,
            copyFilesModal: false,
            copyMyDocModal: false,
            copyingFilesModal: false,
            copyingMyDocsFilesModal: false,
            moveFilesModal: false,
            movingFilesModal: false,
            renameFileModal: false,
            renamingFileModal: false,
            docMenuEl: null,
        });
    }
    onUpgradeModalSuccess(){
        this.history.push("/user/plans");
    }
    renderConfirmationModal(open = true){
        return (
            <ConfirmationModal 
                open={open} 
                onClose={() => this.onUpgradeModalClose(false)} 
                onSuccess={() => this.onUpgradeModalSuccess()}
                confirmationMessage="Please upgrade to paid plan in order to use this feature."
                cancelButtonText={"Cancel"}
                successButtonText={"Upgrade Now"}
            />
        )
    }
    onSignDocument(status = false){
        this.setState({
            docusignModal: status
        });
    }
    onDocusignConnectCallback(){
        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, loading: true});

        const requestData = {
            code: helper.getParam("code"),
            state: helper.getParam("state")
        }

        Api.authorizeDocusign(requestData, source).then(data => {
            history.push("/user/home?dir=%2FMy%20Docs");
            localStorage.setItem("sendlinx_userinfo", JSON.stringify(data.body));
            let newUser = Api.prepareMemberObject(data.body);
            this.store.dispatch({type: "UPDATE_STATE",state: {user: newUser}});
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.setState({cancelToken: null, loading: false});
            }
            this.history.push("/user/home?dir=%2FMy%20Docs");
        });
    }
    onDocusignConnect(type){
        let url = Config.getDocusignSignupUrl();
        if(type === "login"){
            url = Config.getDocusignLoginUrl();
        }
        let state = this.state;
        state.cancelToken = null;
        if(state.currentFile !== null){
            delete state.currentFile.target;
        }
        localStorage.setItem("sendlinx_home_state", JSON.stringify(state));
        window.location.href = url;
    }
    onDocusignSuccess(data){
        this.setState({
            docusignModal: false
        });
        window.open(data.prepareUrl, "_blank");
    }
    render() {
        const { classes } = this.props;
        const { deleteFileModal, uploadFileModal,newFolderModalOneDrivePath,newFolderModalOneDrive, path, shareFileModal,
            downloadModal, downloadingFiles, newFolderModal, creatingFolder, copyFilesModal, copyMyDocModal, copyingFilesModal,
            moveFilesModal, movingFilesModal, renameFileModal, renamingFileModal, files, errorMessage, checked, sharingFiles,
            advShareModal, sharedWith, currentFile, downloadLink, linkName, sharedWithUsers, shareFileId,
            versionsModal, newLinkUploaded, officeFileModal, officeFileAction, newFileModal, newFileExtension, creatingFile,
            newFileError, newFileErrorMessage, imagePreviewModal, pdfFileModal, noPreviewModal, detailsSection, saving,
            docusignModal, copyingMyDocsFilesModal, filesCopiedSize, canceledCopyingMyDocsFilesModal, newEditAccountModal,
            sharepointAccountModal } = this.state;
        const modificationAllowed = this.isModificationAllowed();
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const checkedFiles = this.getCheckedFiles();
        const checkedFile = this.getCheckedFile();
        const canEdit = this.canEditDocument();
        const remaning = (user.quota - user.usage);
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.sidebar}>
                        <Sidebar store={this.store} />
                    </div>
                    {
                        this.getFilesContent(files)
                    }
                    <DeleteFileModal
                        deleteNote={true}
                        confirm={true}
                        deletedFiles={checkedFiles}
                        open={deleteFileModal}
                        onClose={() => this.onModalClose(false)}
                        onSuccess={() => this.onModalSuccess()}
                        loading={saving}
                        errorMessage={errorMessage}
                    />
                    {
                        uploadFileModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                            uploadFileModal ?
                                <UploadFileModal 
                                    open={uploadFileModal} 
                                    store={this.store} 
                                    path={path} 
                                    files={files} 
                                    onClose={() => this.onUploadModal(false)} 
                                    onSuccess={() => this.onUploadModalSuccess()} 
                                    sharedWith={sharedWithUsers}
                                    sharedFileId={shareFileId} 
                                    groupNotification={( shareFileId ? "optional" : '')} 
                                    isHome={true}
                                />
                            :
                                <></>
                    }
                    {
                        advShareModal &&  path.indexOf("/My Docs") !== -1 ?
                            <ShareModal
                                open={advShareModal}
                                onClose={() => this.onAdvShareModal(false)}
                                onSuccess={() => this.onAdvShareModal(false)}
                                files={checkedFiles}
                                store={this.store} 
                                checked={checked}
                                sharedWith={sharedWith}
                                onPermissionsUpdate={this.onPermissionsUpdate}
                            />
                        :
                        <></>
                    }
                    {
                        shareFileModal ?
                            <NewLinkModal 
                                open={shareFileModal} 
                                saving={sharingFiles} 
                                onClose={() => this.onLinkName(false)} 
                                onSuccess={() => this.onShareModalSuccess()}
                                onLinkName={(name, linkPassword) => this.onShareModal(true, name, linkPassword)}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage={ newLinkUploaded === true ? 'Your download link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                                errorMessage={errorMessage}
                                downloadLink={downloadLink}
                                linkName={linkName}
                                store={this.store}
                                linkHash={this.state.linkHash}
                            />
                        :
                        <></>
                    }
                    {
                        downloadModal ?
                            <ProcessingModal 
                                open={downloadModal} 
                                saving={downloadingFiles} 
                                onClose={() => this.onDownloadModal(false)} 
                                onSuccess={() => this.onDownloadModalSuccess()}
                                loadingMessage="Please wait while we prepare your download link. It may take few minutes depending on size and number of file(s)."
                                successMessage="Your file(s) has been processed and your download link is ready."
                                errorMessage={errorMessage}
                                store={this.store} 
                            />
                        :
                        <></>
                    }
                    {
                        newFolderModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                        newFolderModal ?
                            <NewFolderModal
                                open={newFolderModal}
                                saving={creatingFolder}
                                store={this.store} path={path}
                                onClose={() => this.onNewFolderModal(false)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName)}
                                confirmationMessage="Create new folder"
                            />
                        :
                        <></>
                    }
                     {
                        newFolderModalOneDrive ?
                            <NewFolderModal
                                open={newFolderModalOneDrive}
                                saving={false}
                                store={this.store} 
                                path={newFolderModalOneDrivePath}
                                onClose={() => this.onNewFolderModalOneDrive(false,null)}
                                onSuccess={(folderName) => this.onNewFolderModalSuccess(folderName,true)}
                                confirmationMessage="Create new folder"
                            />
                        :
                        <></>
                    }
                    {
                        copyMyDocModal ?
                            <CopyMyDocModal 
                                open={copyMyDocModal}  
                                saving={copyingMyDocsFilesModal}
                                onClose={({addis, fPath, openMyDocs, loadFiles}) =>{ 
                                    if (addis && fPath) {
                                        this.onCopyMyDocModal(false);
                                        this.onNewFolderModalOneDrive(true,fPath);
                                    }else{
                                        this.onCopyMyDocModal(false, null, openMyDocs, loadFiles) ;
                                    }
                                }}
                                onSuccess={(newPath) => this.onCopyDocModalSuccess(newPath)}
                                onCancel={() => this.onCancelCopyFiles()}
                                path={path}
                                leftMemorySize= {remaning}
                                files={checkedFiles}
                                loadingMessage="Please wait while we copy your files. It may take few minutes depending on size of the files."
                                successMessage="Please select destination folder."
                                errorMessage={errorMessage}
                                filesCopiedSize={filesCopiedSize}
                                canceled={canceledCopyingMyDocsFilesModal}
                                openFolderPath={newFolderModalOneDrivePath}
                                store={this.store}
                            />
                        :
                        <></>
                    }
                    {
                        moveFilesModal ?
                            <MoveFilesModal 
                                open={moveFilesModal} 
                                saving={movingFilesModal} 
                                onClose={() => this.onMoveModal(false)} 
                                onSuccess={(newPath) => this.onMoveModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we move your files. It may take few minutes depending on size of the files."
                                successMessage="Move your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        renameFileModal && checkedFile !== null ?
                            <RenameFileModal 
                                open={renameFileModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onRenameModal(false)} 
                                onSuccess={(newPath) => this.onRenameModalSuccess(newPath)}
                                file={checkedFile}
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                    {
                        versionsModal ?
                            <FileVersionModal 
                                store={this.store}
                                open={versionsModal} 
                                saving={renamingFileModal} 
                                onClose={() => this.onManageVersions(false)} 
                                file={checkedFile}
                                errorMessage={errorMessage}
                                sharedWith={sharedWithUsers}
                                sharedFileId={shareFileId} 
                                groupNotification={( shareFileId ? "optional" : '')} 
                            />
                        :
                        <></>

                    }
                    {
                        newFileModal && user.account_type === 5 ?
                            this.renderConfirmationModal()
                        :
                        newFileModal ?
                            <NewFileModal
                                open={newFileModal}
                                saving={creatingFile}
                                store={this.store} 
                                path={path}
                                extension={newFileExtension}
                                onClose={() => this.onNewFileModal(false)}
                                onSuccess={(fileName) => this.onNewFileModalSuccess(fileName)}
                                confirmationMessage="Create new file"
                                error={newFileError}
                                errorMessage={newFileErrorMessage}
                            />
                        :
                        <></>
                    }
                </div>


                {
                        copyFilesModal ?
                            <CopyFilesModal 
                                open={copyFilesModal} 
                                saving={copyingFilesModal} 
                                onClose={() => this.onCopyModal(false)} 
                                onSuccess={(newPath) => this.onCopyModalSuccess(newPath)}
                                path={path}
                                files={checkedFiles}
                                loadingMessage="Please wait while we copy your files. It may take few minutes depending on size of the files."
                                successMessage="Copy your files to new path."
                                errorMessage={errorMessage}
                            />
                        :
                        <></>
                    }
                {
                    officeFileModal && user.account_type === 5 ?
                        this.renderConfirmationModal()
                    :
                    officeFileModal ?
                        <OfficeFileModal 
                            store={this.store}
                            open={officeFileModal} 
                            action={officeFileAction}
                            file={checkedFile}
                            onClose={() => this.onOfficeFileModal(null, false)}
                        />
                    :
                    null
                }
                {
                    imagePreviewModal ?
                        <ImagePreviewModal
                            open={imagePreviewModal}
                            onClose={() => this.onImagePreviewModal(false)}
                            file={checkedFile}
                            onDownload={() => this.onDownloadModal(true)}
                        />
                    :
                    <></>
                }
                {
                    pdfFileModal ?
                        <PdfFileModal 
                            store={this.store}
                            open={pdfFileModal} 
                            file={checkedFile}
                            onClose={() => this.onPdfFileModal(false)}
                        />
                    :
                    null
                }
                {
                    noPreviewModal ?
                        <NoPreviewModal 
                            store={this.store}
                            open={noPreviewModal} 
                            onDownload={() => this.onDownloadModal(true)}
                            onClose={() => this.onNoPreviewModal(false)}
                        />
                    :
                    null
                }
                {
                    detailsSection && checkedFile ?
                        <DetailsSection
                            open={detailsSection}
                            onClose={() => this.onDetailPreviewSection(false)}
                            file={checkedFile}
                            onDownload={() => this.onDownloadModal(true)}
                            modificationAllowed={modificationAllowed}
                        />
                    :
                        <></>
                }
                {
                    path.indexOf("/My Docs") !== -1 ?
                        <ContextMenu id="file_dropdown_menu" onShow={(e) => this.onContextMenuShow(e)}>
                            {
                                modificationAllowed && helper.hasThumbnail(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('imagePreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                modificationAllowed && helper.isPdfDocument(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('pdfPreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                Config.isWopiAllowed() ?
                                    helper.isOfficeDocument(checkedFile) && user.extensions.ms_office ?
                                        <>
                                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('view', data, collect)}>
                                                <Button color="transparent" justIcon className={classes.fileIcon}>
                                                    <img src={officeIcon} alt={"folder"} />
                                                </Button>
                                                Preview
                                            </MenuItem>
                                            {
                                                canEdit ?
                                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('edit', data, collect)}>
                                                        <Button color="transparent" justIcon className={classes.fileIcon}>
                                                            <img src={officeIcon} alt={"folder"} />
                                                        </Button>
                                                        Open in Microsoft Office for the web
                                                    </MenuItem>
                                                :
                                                    null
                                            }
                                        </>
                                    :
                                        null
                                :
                                    null
                            }
                            {
                                path.indexOf("/My Docs") !== -1 ?
                                    <>
                                        {
                                            sharedWith !== null && sharedWith.length > 0 ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('permission', data, collect)}>
                                                    Permissions
                                                </MenuItem>
                                            :
                                            currentFile !== null && currentFile.type === "folder" ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('advShare', data, collect)}>
                                                    Share
                                                </MenuItem>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('share', data, collect)}>
                                    Share
                                </MenuItem>
                            }
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('delete', data, collect)}>
                                Delete
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('download', data, collect)}>
                                Download
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('copy', data, collect)}>
                                Copy
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('move', data, collect)}>
                                Move
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('rename', data, collect)}>
                                Rename
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('replace', data, collect)}>
                                Previous Version(s)
                            </MenuItem>
                            {
                                modificationAllowed ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('detail', data, collect)}>
                                        View Details
                                    </MenuItem>
                                :
                                    <></>
                            }
                            {
                                helper.canSignDocument(checkedFile) && user.extensions.docusign && user.extensions.docusign.status ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('sign', data, collect)}>
                                        Send For Signature
                                    </MenuItem>
                                :
                                null
                            }
                        </ContextMenu>
                    : path.includes("/OneDrive") || path.includes("/Sharepoint") ?
                        <ContextMenu id="file_dropdown_menu" onShow={(e) => this.onContextMenuShow(e)}>
                            {
                                modificationAllowed && helper.hasThumbnail(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('imagePreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                modificationAllowed && helper.isPdfDocument(checkedFile) ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('pdfPreview', data, collect)}>
                                        Preview
                                    </MenuItem>
                                :   
                                    <></>
                            }
                            {
                                Config.isWopiAllowed() ?
                                    helper.isOfficeDocument(checkedFile) && user.extensions.ms_office ?
                                        <>
                                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('view', data, collect)}>
                                                <Button color="transparent" justIcon className={classes.fileIcon}>
                                                    <img src={officeIcon} alt={"folder"} />
                                                </Button>
                                                Preview
                                            </MenuItem>
                                            {
                                                canEdit ?
                                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('edit', data, collect)}>
                                                        <Button color="transparent" justIcon className={classes.fileIcon}>
                                                            <img src={officeIcon} alt={"folder"} />
                                                        </Button>
                                                        Open in Microsoft Office for the web
                                                    </MenuItem>
                                                :
                                                    null
                                            }
                                        </>
                                    :
                                        null
                                :
                                    null
                            }
                            {
                                path.indexOf("/My Docs") !== -1 ?
                                    <>
                                        {
                                            sharedWith !== null && sharedWith.length > 0 ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('permission', data, collect)}>
                                                    Permissions
                                                </MenuItem>
                                            :
                                            currentFile !== null && currentFile.type === "folder" ?
                                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('advShare', data, collect)}>
                                                    Share
                                                </MenuItem>
                                            :
                                            <></>
                                        }
                                    </>
                                :
                                <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('share', data, collect)}>
                                    Share
                                </MenuItem>
                            }
                            <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('download', data, collect)}>
                                Download
                            </MenuItem>
                            <MenuItem data={{}} onClick={(e) => this.myDocModal('CopyToMyDocs')}>
                                Copy
                            </MenuItem>
                            {/* <MenuItem data={{}} onClick={(e) => this.myDocModal('SyncToMyDocs')}>
                                Sync
                            </MenuItem> */}
                            {
                              modificationAllowed ?
                                    <MenuItem data={{}} onClick={(e, data, collect) => this.onContextMenuItemClick('detail', data, collect)}>
                                        View Details
                                    </MenuItem>
                                :
                                    <></>
                            }
                        </ContextMenu>
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                    {
                        officeFileModal === false ?
                            <link rel="shortcut icon" href="favicon.ico" />
                        :
                        null
                    }
                </Helmet>
                <form ref={this.editDocRef} target='_blank' method='GET' style={{display: 'none'}}>
                    {/* <input ref={this.editDocTokenRef} name="oauth-token" type="hidden" /> */}
                </form>
                {
                    docusignModal ?
                        <SignModal
                            open={docusignModal}
                            files={checkedFiles}
                            onClose={() => this.onSignDocument(false)}
                            store={this.store}
                            onDocusignConnect={(type) => this.onDocusignConnect(type)}
                            onSignSuccess={(data) => this.onDocusignSuccess(data)}
                        />
                    :
                    null
                }
                {
                    newEditAccountModal && (
                        <NewEditAccountModal
                            open={newEditAccountModal}
                            user={user}
                            onClose={(status, submit) => this.onNewEditAccountModal(false, submit)}
                            confirmationMessage="Confirm Default Destination Folder"
                            store={this.store}
                        />
                    )
                }
                {
                    sharepointAccountModal && (
                        <SharepointAccountModal
                            open={sharepointAccountModal}
                            user={user}
                            onClose={(status, submit) => this.onSharepointAccountModal(false, submit)}
                            store={this.store}
                        />
                    )
                }
            </div>
        )
    }
}

UserHome.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserHome);