/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    dangerColor,
    successColor,
    whiteColor,
    grayColor,
    defaultFont
  } from "../../material-kit-pro-react.jsx";
  
  const customInputStyle = (theme) => ({
    disabled: {
      "&:before": {
        backgroundColor: "transparent !important"
      }
    },
    underline: {
      "&:hover:not($disabled):before,&:before": {
        borderBottomColor: grayColor[11] + " !important",
        borderBottomWidth: "1px !important"
      },
      "&:after": {
        borderBottomColor: theme.customize.buttonsColor
      }
    },
    underlineError: {
      "&:after": {
        borderBottomColor: dangerColor[0]
      }
    },
    underlineSuccess: {
      "&:after": {
        borderBottomColor: successColor[0]
      }
    },
    labelRoot: {
      ...defaultFont,
      color: grayColor[12] + " !important",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      top: "-5px",
      letterSpacing: "unset",
      "& + $underline": {
        marginTop: "0px"
      },
      "&.MuiInputLabel-shrink": {
          top: "0px"
      }
    },
    labelRootError: {
      color: dangerColor[0] + " !important"
    },
    labelRootSuccess: {
      color: successColor[0] + " !important"
    },
    feedback: {
      position: "absolute",
      bottom: "4px",
      right: "0",
      zIndex: "2",
      display: "block",
      width: "24px",
      height: "24px",
      textAlign: "center",
      pointerEvents: "none"
    },
    formControl: {
      margin: "0 0 17px 0",
      paddingTop: "27px",
      position: "relative",
      "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
        color: grayColor[13]
      }
    },
    whiteUnderline: {
      "&:hover:not($disabled):before,&:before": {
        borderBottomColor: whiteColor
      },
      "&:after": {
        borderBottomColor: whiteColor
      }
    },
    input: {
      color: grayColor[13],
      height: "unset",
      "&,&::placeholder": {
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400",
        lineHeight: "1.42857",
        opacity: "1"
      },
      "&::placeholder": {
        color: grayColor[12]
      },
    },
    whiteInput: {
      "&,&::placeholder": {
        color: whiteColor,
        opacity: "1"
      }
    },
    passwordInput: {
        fontSize: '20px',
        letterSpacing: '3px',
        textSecurity: 'disc', // for WebKit-based browsers
        WebkitTextSecurity: 'disc', // for WebKit-based browsers
        MozTextSecurity: 'disc', // for Firefox
        color: grayColor[13],
        height: "unset",
        "&,&::placeholder": {
            fontSize: "14px",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: "400",
            lineHeight: "1.42857",
            opacity: "1"
        },
        "&::placeholder": {
            color: grayColor[12]
        },
    },
    verifiedCheck: {
      backgroundColor: whiteColor,
      borderRadius: "100%",
      padding: "2px",
      boxShadow: "0px 0px 2px 0px "+successColor[0],
      bottom: "6px"
    },
    tagsInput: {
      "& label": {
          top: "0px !important",
          transform: "translate(0, 1.5px) scale(0.75)",
          transformOrigin: "top left"
      },
      "&.MuiFormControl-root .MuiInputBase-root": {
          marginLeft: "4px",
          flexGrow: "1",
          flexShrink: "1",
          "&:after, &:before": {
              borderBottom: "none !important"
          },
          "& input": {
              padding: "4px 8px 4px 4px",
              width: "auto",
              flexGrow: "1",
              flexShrink: "1"
          }
      }
    },
    tags: {
      border: "1px solid "+grayColor[6],
      padding: "4px 10px 0px 4px",
      display: "flex",
      flexGrow: "1",
      flexWrap: "wrap"
    },
    tag: {
      backgroundColor: theme.customize.buttonsColor,
      color: whiteColor,
      height: "24px",
      lineHeight: "24px",
      fontSize: "12px",
      borderRadius: "12px",
      display: "flex",
      margin: "1px 1px 4px 4px",
      padding: "0px 7px 0px 5px",
      cursor: "pointer",
    },
    closeTag: {
      display: "flex",
      alignItems: "center",
      color: whiteColor,
      "& svg": {
          height: "18px",
          fill: whiteColor
      }
    },
    inputIcon: {
      paddingRight: "30px !important"
    },
  });
  
  export default customInputStyle;
  